import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	dashboards: []
}

export const getters = {
	dashboards (state) {
		return state.dashboards
	}
}

export const mutations = {
	SET_DASHBOARDS (state, { dashboards, addCookieModule }) {
		if (addCookieModule === true) {
			const legacyDashboard = {
				id: 0,
				name: 'Cookie Module',
				isDefault: dashboards.some(d => !d.isDefault) // non of the database dashboards are default set this one as default
			}
			state.dashboards = [legacyDashboard, ...dashboards]
		} else {
			state.dashboards = [...dashboards]
		}
	}
}

export const actions = {
	getDashboards ({ commit }, addCookieModule) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/dashboard')
				.then(response => {
					const dashboards = response.data
					commit('SET_DASHBOARDS', { dashboards, addCookieModule })
					resolve(dashboards)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
