import { computed, ref } from '@vue/composition-api'

const NO_PREFERENCE_WIDGET_TEXT_FILTER = ''

const preferenceWidgetSearchQueryState = ref(NO_PREFERENCE_WIDGET_TEXT_FILTER)

export const changePreferenceWidgetSearchQuery = searchQuery => {
	preferenceWidgetSearchQueryState.value = searchQuery
}

export const preferenceWidgetSearchQuery = computed({
	get () {
		return preferenceWidgetSearchQueryState.value
		// your getter
	},
	set (value) {
		changePreferenceWidgetSearchQuery(value)
		// your setter
	}
})

export const resetPreferenceWidgetFilters = () => {
	changePreferenceWidgetSearchQuery(NO_PREFERENCE_WIDGET_TEXT_FILTER)
}
