import { computed, ref } from '@vue/composition-api'

const NO_CONFIRMATION_PAGE_TEXT_FILTER = ''

const confirmationPageSearchQueryState = ref(NO_CONFIRMATION_PAGE_TEXT_FILTER)

export const changeConfirmationPageSearchQuery = searchQuery => {
	confirmationPageSearchQueryState.value = searchQuery
}

export const confirmationPageSearchQuery = computed({
	get () {
		return confirmationPageSearchQueryState.value
		// your getter
	},
	set (value) {
		changeConfirmationPageSearchQuery(value)
		// your setter
	}
})

export const resetConfirmationPageFilters = () => {
	changeConfirmationPageSearchQuery(NO_CONFIRMATION_PAGE_TEXT_FILTER)
}
