import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	headers: [],
	selectedHeader: {}
}

export const getters = {
	headers (state) {
		return state.headers
	}
}

export const mutations = {
	SET_HEADERS (state, headers) {
		const headersOrdered = headers
		headersOrdered.sort((a, b) => a.headerName.localeCompare(b.headerName))
		state.headers = headersOrdered
	},
	SET_HEADER (state, header) {}
}

export const actions = {
	getHeader ({ commit }, id) {
		return configApi.get('/api/headers/' + id).then(response => {
			const header = response.data
			commit('SET_HEADER', header)
			return header
		})
	},
	getHeaders ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/headers')
				.then(response => {
					const headers = response.data.headers
					commit('SET_HEADERS', headers)
					resolve(headers)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
