import { format } from 'date-fns'
import { configApi } from '../../../../shared/utils/http-client.js'
import { DATE_FORMAT } from '../../../../shared/utils/date-formatting.js'

export const state = {
	cookiePolicyTables: [],
	currentPolicyTableId: Number,
	policyTableScripts: [],
	cookiePolicyEmbedCode: ''
}

export const getters = {
	cookiePolicyTables (state) {
		return state.cookiePolicyTables
	},
	currentPolicyTableId (state) {
		return state.currentPolicyTableId
	},
	policyTableScripts (state) {
		return state.policyTableScripts
	},
	cookiePolicyEmbedCode (state) {
		return state.cookiePolicyEmbedCode
	}
}

export const mutations = {
	SET_EMBEDCODE (state, cookiePolicyEmbedCode) {
		state.cookiePolicyEmbedCode = cookiePolicyEmbedCode
	},
	SET_COOKIEPOLICYTABLES (state, cookiePolicyTables) {
		state.cookiePolicyTables = cookiePolicyTables
	},
	SET_COOKIEPOLICYTABLE (state, cookiePolicyTable) {
		const index = state.cookiePolicyTables.findIndex(
			policyTable =>
				policyTable.policyTableId === cookiePolicyTable.policyTableId
		)
		if (index > -1) state.cookiePolicyTables.splice(index, 1)
		state.cookiePolicyTables.push(cookiePolicyTable)
	},
	SET_COOKIEPOLICYTABLESCRIPTS (state, policyTableScripts) {
		state.policyTableScripts = policyTableScripts
	},
	REMOVE_CURRENT_POLICYTABLEID (state) {
		state.currentPolicyTableId = null
	},
	SET_CURRENT_POLICYTABLEID (state, id) {
		state.currentPolicyTableId = id
	},
	DELETE_COOKIEPOLICYTABLE (state, policyTableId) {
		const index = state.cookiePolicyTables.findIndex(
			policyTable => policyTable.policyTableId === policyTableId
		)
		state.cookiePolicyTables.splice(index, 1)
	},
	CREATE_COOKIEPOLICYTABLE (state, policyTable) {
		state.cookiePolicyTables.push(policyTable)
		state.currentPolicyTableId = policyTable.policyTableId
	},
	UPDATE_COOKIEPOLICYTABLE (state, policyTable) {
		const updatedPolicyTable = state.cookiePolicyTables.find(
			statePolicyTable =>
				statePolicyTable.policyTableId === policyTable.cookiePolicyTableId
		)
		updatedPolicyTable.policyTableId = policyTable.cookiePolicyTableId
		updatedPolicyTable.policyTableName = policyTable.cookiePolicyTableName
		updatedPolicyTable.policyTableDescription =
      policyTable.cookiePolicyTableDescription
		updatedPolicyTable.brandId = policyTable.brandId
	},
	SET_PUBLISH_COOKIEPOLICYTABLE (state, policyTableId) {}
}

export const actions = {
	getCookiePolicyTables ({ commit }) {
		return configApi
			.get('/api/CookiePolicy/CookiePolicyTables')
			.then(response => {
				const cookiePolicyTables = response.data.map(policyTable => ({
					...policyTable,
					createdDate: format(new Date(policyTable.createdDate), DATE_FORMAT)
				}))
				commit('SET_COOKIEPOLICYTABLES', cookiePolicyTables)
				return cookiePolicyTables
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	getCookiePolicyTable ({ commit }, policyTableId) {
		return configApi
			.get('/api/CookiePolicy/CookiePolicyTables/' + policyTableId)
			.then(response => {
				const cookiePolicyTable = response.data.cookiePolicyTable
				commit('SET_COOKIEPOLICYTABLE', cookiePolicyTable)
				const cookiePolicyTableScripts = response.data.cookiePolicyTableScripts
				commit('SET_COOKIEPOLICYTABLESCRIPTS', cookiePolicyTableScripts)
				return cookiePolicyTableScripts
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	getEmbedCode ({ commit }, getEmbedCodeObject) {
		const policyTableId = getEmbedCodeObject.policyTableId
		const languageId = getEmbedCodeObject.languageId
		const divId = getEmbedCodeObject.divId
		const groupingId = getEmbedCodeObject.groupingId
		return new Promise((resolve, reject) => {
			configApi
				.get(
					'api/CookiePolicy/EmbedCode?CookiePolicyTableId=' +
            policyTableId +
            '&LanguageId=' +
            languageId +
            '&divId=' +
            divId +
            '&groupingId=' +
            groupingId
				)
				.then(response => {
					const cookiePolicyEmbedCode = response.data
					commit('SET_EMBEDCODE', cookiePolicyEmbedCode)
					resolve(cookiePolicyEmbedCode)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	removeCurrentPolicyTableId ({ commit }) {
		commit('REMOVE_CURRENT_POLICYTABLEID')
	},
	setCurrentPolicyTableId ({ commit }, id) {
		commit('SET_CURRENT_POLICYTABLEID', id)
	},
	deleteCookiePolicyTable ({ commit }, policyTableId) {
		return configApi
			.delete('/api/CookiePolicy/' + policyTableId)
			.then(() => {
				commit('DELETE_COOKIEPOLICYTABLE', policyTableId)
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	createCookiePolicyTable ({ commit }, policyTable) {
		return configApi
			.post('/api/CookiePolicy/CookiePolicyTables/', policyTable)
			.then(response => {
				const newPolicyTable = response.data
				policyTable.policyTableId = newPolicyTable.newCookiePolicyTableId
				commit('CREATE_COOKIEPOLICYTABLE', policyTable)
				return policyTable
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	updateCookiePolicyTable ({ commit }, policyTable) {
		return configApi
			.put('/api/CookiePolicy/CookiePolicyTables/', policyTable)
			.then(() => {
				commit('UPDATE_COOKIEPOLICYTABLE', policyTable)
				return policyTable
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	updateCookiePolicyTableScripts ({ commit }, policyTableScripts) {
		return configApi
			.put('/api/CookiePolicy/ScriptConfiguration/', policyTableScripts)
			.then(() => {
				commit('SET_COOKIEPOLICYTABLESCRIPTS', policyTableScripts)
				return policyTableScripts
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},

	publishCookiePolicyTable ({ commit }, policyTableId) {
		return new Promise((resolve, reject) => {
			configApi
				.get('api/CookiePolicy/CookiePolicy/Publish/' + policyTableId)
				.then(response => {
					const cookiePolicyEmbedCode = response.data
					commit('SET_PUBLISH_COOKIEPOLICYTABLE', cookiePolicyEmbedCode)
					resolve(cookiePolicyEmbedCode)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
