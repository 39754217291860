import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	languages: [],
	assignedLanguages: []
}

export const getters = {
	languages (state) {
		return state.languages
	},
	assignedLanguages (state) {
		return state.assignedLanguages
	}
}

export const mutations = {
	SET_LANGUAGES (state, languages) {
		const languagesOrdered = languages
		languagesOrdered.sort((a, b) =>
			a.languageName.localeCompare(b.languageName)
		)
		state.languages = languagesOrdered
	},
	SET_ASSIGNEDLANGUAGES (state, languages) {
		const languagesOrdered = languages
		languagesOrdered.sort((a, b) =>
			a.languageName.localeCompare(b.languageName)
		)
		state.assignedLanguages = languagesOrdered
	}
}

export const actions = {
	getLanguages ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/Languages')
				.then(response => {
					const languages = response.data
					commit('SET_LANGUAGES', languages)
					resolve(languages)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getAssignedLanguages ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/Languages/AssignedLanguages')
				.then(response => {
					const languages = response.data
					commit('SET_ASSIGNEDLANGUAGES', languages)
					resolve(languages)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
