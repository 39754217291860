import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	apiKeys: []
}

export const getters = {
	apiKeys (state) {
		return state.apiKeys
	}
}

export const mutations = {
	SET_APIKEYS (state, apiKeys) {
		state.apiKeys = apiKeys
	}
}

export const actions = {
	getApiKeys ({ commit }) {
		return configApi.get('/api/ApiKeys').then(response => {
			const apiKeys = response.data
			commit('SET_APIKEYS', apiKeys)
			return apiKeys
		})
	}
}
