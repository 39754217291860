
<template>
	<div class="d-flex fill-height cassie-application-background">
		<NavBar
			:links="links"
			:change-show-filters="changeShowFilters"
			:reset-admin-portal-filters="resetAdminPortalFilters"
			class="flex-grow-0 flex-shrink-0"
		/>
		<v-overlay
			z-index="999"
			:value="showSpinner"
		>
			<v-progress-circular
				indeterminate
				size="64"
			/>
		</v-overlay>
		<slot />
	</div>
</template>

<script>
import NavBar from '../components/nav-bar.vue'

export default {
	components: { NavBar },
	props: {
		links: {
			type: Array,
			default: () => []
		},
		showSpinner: {
			type: Boolean,
			default: false
		},
		changeShowFilters: {
			type: Function,
			default: () => {}
		},
		resetAdminPortalFilters: {
			type: Function,
			default: () => {}
		}
	}
}
</script>

<style lang="scss">
.cassie-application-background {
	background: var(--global-main-background-color);
}
</style>
