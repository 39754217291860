import { ref } from '@vue/composition-api'

export const snackbar = ref(null)

export const showSnackbar = data => {
	if (typeof data === 'string') {
		snackbar.value = {
			text: data,
			color: 'success'
		}
	} else {
		snackbar.value = data
	}
}
