import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

export const getBrands = () => {
	return configApi.get('/api/Brands').then(({ data }) => ({
		data: {
			isMultiBrand: data.isMultiBrand,
			brands: data.brands.map(brand => ({
				...brand,
				value: brand.brandId,
				text: brand.brandName,
				createdDate: format(new Date(brand.dateCreated), DATE_FORMAT)
			}))
		}
	}))
}

export const upsertBrand = brand => configApi.post('/api/Brands/Upsert', { brand })

export const createBrand = brand => configApi.post('/api/Brands', { brand })

export const updateBrand = brand => configApi.put('/api/Brands', { brand })

export const upsertBrandLabel = async brandLabel => {
	const brandLabelData = {
		adminPortalBrandLabel: brandLabel
	}
	await configApi.post('/api/Brands/UpsertBrandLabel', brandLabelData)
}
