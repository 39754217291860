import { computed, ref } from '@vue/composition-api'

const NO_SITE_SCANNER_TEXT_FILTER = ''
const NO_SITE_SCANNER_DOMAIN_FILTER = ''

const siteScannerSearchQueryState = ref(NO_SITE_SCANNER_TEXT_FILTER)
const siteScannerDomainSearchQueryState = ref(NO_SITE_SCANNER_DOMAIN_FILTER)

export const changeSiteScannerSearchQuery = searchQuery => {
	siteScannerSearchQueryState.value = searchQuery
}

export const changeSiteScannerDomainSearchQuery = searchQuery => {
	siteScannerDomainSearchQueryState.value = searchQuery
}

export const siteScannerSearchQuery = computed({
	get () {
		return siteScannerSearchQueryState.value
	},
	set (value) {
		changeSiteScannerSearchQuery(value)
	}
})

export const siteScannerDomainSearchQuery = computed({
	get () {
		return siteScannerDomainSearchQueryState.value
	},
	set (value) {
		changeSiteScannerDomainSearchQuery(value)
	}
})

export const resetSiteScannerFilters = () => {
	changeSiteScannerSearchQuery(NO_SITE_SCANNER_TEXT_FILTER)
	changeSiteScannerDomainSearchQuery(NO_SITE_SCANNER_DOMAIN_FILTER)
}
