import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_COLLECTION_TEMPLATE_NAME_FILTER = ''

const cookieCollectionTemplateSearchQueryState = ref(NO_COOKIE_COLLECTION_TEMPLATE_NAME_FILTER)

export const changeCookieCollectionTemplateSearchQuery = searchQuery => {
	cookieCollectionTemplateSearchQueryState.value = searchQuery
}

export const cookieCollectionTemplateSearchQuery = computed({
	get () {
		return cookieCollectionTemplateSearchQueryState.value
	},
	set (value) {
		changeCookieCollectionTemplateSearchQuery(value)
	}
})

export const resetCookieCollectionTemplateFilters = () => {
	changeCookieCollectionTemplateSearchQuery(NO_COOKIE_COLLECTION_TEMPLATE_NAME_FILTER)
}
