/* eslint-disable max-lines */
import { configApi } from '../../../../shared/utils/http-client.js'
import {
	getBasicDetails,
	getColourSchemes,
	getConsentBannerPreBanner,
	getConsentBannerConfiguration,
	getConsentBannerScripts,
	getConsentBannerCisCookies,
	getConsentBannerProfiles,
	getConsentBannerProfile,
	getEmbedCode,
	publishConsentBannerProfile
} from '../../services/ConsentBannerService.js'

export const state = {
	consentBanners: [],
	currentBanner: {
		currentBannerId: null,
		configurationStatusId: 0,
		basicDetails: null,
		preBanner: null,
		gpcBanner: null,
		banner: null,
		bannerCategories: [],
		bannerScripts: [],
		bannerCisCookies: [],
		languages: [],
		translations: [],
		existingTranslations: [],
		translationDetails: null
	},
	consentBannerProfiles: {},
	preBannerColorSchemes: [],
	bannerColorSchemes: [],
	embedCode: null,
	globalSettings: {
		preBannerHidden: false,
		gpcEnabled: false,
		cisEnabled: false
	},
	cookiesPreview: {}
}

export const getters = {
	consentBanners (state) {
		return state.consentBanners
	},
	currentConsentBannerId (state) {
		return state.currentBanner.currentBannerId
	},
	currentBannerDetails (state) {
		return state.currentBanner.basicDetails
	},
	currentBannerPreBanner (state) {
		return state.currentBanner.preBanner
	},
	currentBannerGpcBanner (state) {
		return state.currentBanner.gpcBanner
	},
	currentBannerConfiguration (state) {
		return state.currentBanner.banner
	},
	currentBannerCategories (state) {
		return state.currentBanner.bannerCategories
	},
	currentBannerScripts (state) {
		return state.currentBanner.bannerScripts
	},
	configurationStatusId (state) {
		return state.currentBanner.configurationStatusId
	},
	lastSavedStep (state) {
		return state.currentBanner.configurationStatusId - 1
	},
	preBannerColorSchemes (state) {
		return state.preBannerColorSchemes
	},
	consentBannerProfiles (state) {
		return state.consentBannerProfiles
	},
	embedCode (state) {
		return state.embedCode
	},

	preBannerHidden (state) {
		return state.globalSettings.preBannerHidden
	},
	globalGpcEnabled (state) {
		return state.globalSettings.gpcEnabled
	},
	globalCisEnabled (state) {
		return state.globalSettings.cisEnabled
	},

	cookiesPreview (state) {
		return state.cookiesPreview
	},
	currentBannerLanguages (state) {
		return state.currentBanner.languages
	},
	currentBannerTranslations (state) {
		return state.currentBanner.translations
	},
	currentBannerExistingTranslations (state) {
		return state.currentBanner.existingTranslations
	},
	currentBannerTranslationDetails (state) {
		return state.currentBanner.translationDetails
	}
}

export const mutations = {
	SET_CONSENTBANNERS (state, consentBanners) {
		state.consentBanners = consentBanners
	},
	SET_CONSENTBANNERBASICDETAILS (state, basicDetails) {
		state.currentBanner.basicDetails = basicDetails
	},
	SET_PREBANNERCONFIGURATION (state, preBannerConfiguration) {
		state.currentBanner.preBanner = preBannerConfiguration
	},
	SET_GPCBANNERCONFIGURATION (state, gpcBannerConfiguration) {
		state.currentBanner.gpcBanner = gpcBannerConfiguration
	},
	SET_BANNERCONFIGURATION (state, bannerConfiguration) {
		state.currentBanner.banner = bannerConfiguration
	},
	UPDATE_BANNERCONFIGURATION (state, bannerConfiguration) {
		state.currentBanner.banner = bannerConfiguration
	},
	REMOVE_CURRENT_BANNER (state) {
		state.currentBanner.currentBannerId = 0
		state.currentBanner.configurationStatusId = 0
		state.currentBanner.details = []
	},
	UPDATE_CONSENTBANNERBASICDETAILS (state, updatedBasicDetails) {
		state.currentBanner.details = updatedBasicDetails
	},
	UPDATE_PREBANNERCONFIGURATION (state, preBannerData) {
		state.currentBanner.preBanner = preBannerData
	},
	CREATE_CONSENTBANNER (state, consentBanner) {
		state.consentBanners.push(consentBanner)
		state.currentBanner.currentBannerId = consentBanner.consentBannerId
	},
	DELETE_CONSENTBANNER (state, id) {
		const index = state.consentBanners.findIndex(
			banner => banner.consentBannerId === id
		)
		state.consentBanners.splice(index, 1)
	},
	SET_CURRENTBANNERID (state, id) {
		state.currentBanner.currentBannerId = id
	},
	SET_CONFIGURATIONSTATUSID (state, id) {
		state.currentBanner.configurationStatusId = id
	},
	UPDATED_CONFIGURATIONSTATUS (state, consentBanner) {},
	UPDATED_CISCOOKIES (state, cisCookies) {},
	SET_BANNERCATEGORIES (state, categories) {
		state.currentBanner.bannerCategories = categories
	},
	SET_BANNERSCRIPTS (state, scripts) {
		state.currentBanner.bannerScripts = scripts
	},
	SET_BANNERCISCOOKIES (state, cisCookies) {
		state.currentBanner.bannerCisCookies = cisCookies
	},
	UPDATE_BANNERCATEGORIES (state, bannerCategoryData) {
		const bannerCategories = bannerCategoryData.categoryData.categories
		const categoryRoot =
			bannerCategoryData.rootState.cookieCategories.cookieCategories

		bannerCategories.forEach(bannerCategory => {
			const matchingCategory = categoryRoot.find(
				rootCat => rootCat.categoryId === bannerCategory.cookieCategoryId
			)
			bannerCategory.cookieCategoryName = matchingCategory.categoryName
			bannerCategory.cookieCategoryName = matchingCategory.categoryName
		})
		state.currentBanner.bannerCategories = bannerCategories
	},
	UPDATE_BANNERSCRIPTS () {},
	DELETE_BANNERCATEGORY (state, deletedCategory) {
		const index = state.currentBanner.bannerCategories.findIndex(
			category =>
				category.cookieCategoryId === deletedCategory.cookieCategoryId
		)
		state.currentBanner.bannerCategories.splice(index, 1)
	},
	SET_PREBANNERCOLORSCHEMES (state, colorSchemes) {
		state.preBannerColorSchemes = colorSchemes
	},
	SET_BANNERCOLORSCHEMES (state, colorSchemes) {
		state.bannerColorSchemes = colorSchemes
	},
	SET_PROFILES (state, profiles) {
		state.consentBannerProfiles = []
		state.consentBannerProfiles = profiles
	},
	UPDATE_CONSENTBANNERDEFAULTPROFILE (state, updatedBannerDetails) {
		const defaultBanner = state.consentBannerProfiles.find(defaultBanner => defaultBanner.isDefault === 1)
		defaultBanner.isGpc = updatedBannerDetails.gpcEnabled === true ? 1 : 0
	},
	SET_PROFILE (state, profile) {},
	CREATE_PROFILE (state, profile) {
		const newProfile = {
			accessKeyName: profile.accessKeyName,
			consentBannerId: profile.consentBannerId,
			loadTemplateCss: profile.loadTemplateCSS,
			loadTemplateHtml: profile.loadTemplateHTML,
			loadTemplateJs: profile.loadTemplateJS,
			profileId: profile.profileId,
			profileName: profile.profileName
		}
		state.consentBannerProfiles.push(newProfile)
	},
	UPDATE_PROFILE (state, updatedProfile) {
		const newProfileDetails = {
			accessKeyName: updatedProfile.accessKeyName,
			consentBannerId: updatedProfile.consentBannerId,
			isGpc: updatedProfile.isGpc,
			loadTemplateCss: updatedProfile.loadTemplateCss,
			loadTemplateHtml: updatedProfile.loadTemplateHtml,
			loadTemplateJs: updatedProfile.loadTemplateJs,
			profileId: updatedProfile.profileId,
			profileName: updatedProfile.profileName
		}
		const index = state.consentBannerProfiles.findIndex(
			profile => profile.profileId === updatedProfile.profileId
		)

		state.consentBannerProfiles[index] = newProfileDetails
	},
	DELETE_PROFILE (state, deletedProfileId) {
		const index = state.consentBannerProfiles.findIndex(
			profile => profile.profileId === deletedProfileId
		)
		state.consentBannerProfiles.splice(index, 1)
	},
	SET_EMBEDCODE (state, embedCode) {
		state.embedCode = embedCode
	},
	PUBLISH_PROFILE (state, responseMessage) {},
	SET_GLOBALPREBANNER (state, preBannerHidden) {
		state.globalSettings.preBannerHidden = preBannerHidden
	},
	SET_GLOBALGPC (state, gpcValue) {
		state.globalSettings.gpcEnabled = gpcValue
	},
	SET_GLOBALCIS (state, cisValue) {
		state.globalSettings.cisEnabled = cisValue
	},

	SET_COOKIESPREVIEWDATA (state, previewData) {
		state.cookiesPreview = previewData
	},
	CREATE_TRANSLATION (state, translation) {
		state.currentBanner.translations.push(translation)
	},
	SET_TRANSLATIONS (state, translations) {
		state.currentBanner.existingTranslations = translations
	},
	SET_TRANSLATION (state, translation) {
		state.currentBanner.translationDetails = translation
	},
	DELETE_TRANSLATION (state, translation) {
		const index = state.currentBanner.existingTranslations.findIndex(
			currentTranslation =>
				currentTranslation.languageId === translation.languageId
		)
		state.currentBanner.existingTranslations.splice(index, 1)
	},
	RESET_ALL_TRANSLATIONS (state) {
		state.currentBanner.existingTranslations = []
	}
}

export const actions = {
	getConsentBanners ({ commit }) {
		return configApi
			.get('/api/ConsentBanner')
			.then(response => {
				const consentBanners = response.data.consentBanners
				commit('SET_CONSENTBANNERS', consentBanners)
				return consentBanners
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	removeCurrentConsentBanner ({ commit }) {
		commit('REMOVE_CURRENT_BANNER')
	},
	// getBannerDetails
	getConsentBannerBasicDetails ({ commit }, id) {
		return new Promise((resolve, reject) => {
			getBasicDetails(id)
				.then(response => {
					const basicDetails = response.data
					commit('SET_CONSENTBANNERBASICDETAILS', basicDetails)
					resolve(basicDetails)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	deleteConsentBanner ({ commit }, id) {
		return configApi
			.delete('/api/ConsentBanner/' + id)
			.then(response => {
				const deletedConsentBanner = response.data
				commit('DELETE_CONSENTBANNER', id)
				return deletedConsentBanner
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	forceConsentBanner ({ commit }, id) {
		return configApi
			.get('/api/ConsentBanner/RequestReconsent/' + id)
	},
	createConsentBanner ({ commit }, banner) {
		return configApi
			.post('/api/ConsentBanner/BasicDetails', banner)
			.then(response => {
				const newBanner = response.data
				banner.consentBannerId = newBanner.newConsentBannerId

				commit('CREATE_CONSENTBANNER', banner)

				return banner
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	// update basic details
	updateConsentBannerBasicDetails ({ commit }, details) {
		return configApi
			.put('/api/ConsentBanner/BasicDetails/', details)
			.then(response => {
				const updatedDetails = response.data
				commit('SET_GLOBALGPC', details.gpcEnabled)
				commit('UPDATE_CONSENTBANNERBASICDETAILS', details)
				commit('UPDATE_CONSENTBANNERDEFAULTPROFILE', details)
				return updatedDetails
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	// Pre Banner Put
	updateConsentBannerPreBanner ({ commit }, preBanner) {
		return configApi
			.put('/api/ConsentBanner/PreBannerConfiguration/', preBanner)
			.then(response => {
				const preBannerData = response.data

				commit('UPDATE_PREBANNERCONFIGURATION', preBanner)
				return preBannerData
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	// Pre Banner Get
	getConsentBannerPreBanner ({ commit }, id) {
		return new Promise((resolve, reject) => {
			getConsentBannerPreBanner(id)
				.then(response => {
					const preBannerConfiguration =
						response.data === '' ? null : response.data
					commit('SET_PREBANNERCONFIGURATION', preBannerConfiguration)
					resolve(preBannerConfiguration)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	// gpc pre banner get
	getGpcPreBanner ({ commit }, id) {
		return configApi.get('/api/ConsentBanner/GpcBannerConfiguration?consentBannerId=' + id).then(response => {
			const gpcBannerData = response.data

			commit('SET_GPCBANNERCONFIGURATION', gpcBannerData)
			return gpcBannerData
		})
	},

	// gpc pre banner put
	putGpcPreBanner ({ commit }, gpcBanner) {
		return configApi.put('/api/ConsentBanner/GpcBannerConfiguration/', gpcBanner)
	},
	// banner Get
	getConsentBannerConfiguration ({ commit }, id) {
		return new Promise((resolve, reject) => {
			getConsentBannerConfiguration(id)
				.then(response => {
					const bannerConfiguration =
						response.data === '' ? null : response.data
					commit('SET_BANNERCONFIGURATION', bannerConfiguration)
					resolve(bannerConfiguration)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	// Banner put
	updateConsentBannerConfiguration ({ commit }, banner) {
		return configApi
			.put('/api/ConsentBanner/Configuration/', banner)
			.then(response => {
				const bannerData = response.data

				commit('UPDATE_BANNERCONFIGURATION', banner)
				return bannerData
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	// banner get categories
	getConsentBannerCategories ({ commit }, id) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/ConsentBanner/CategoryConfiguration/' + id)
				.then(response => {
					const bannerCategories = response.data
					commit('SET_BANNERCATEGORIES', bannerCategories)
					resolve(bannerCategories)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// banner categories
	updateBannerCategories ({ commit, rootState }, categoryData) {
		return configApi
			.put('/api/ConsentBanner/CategoryConfiguration/', categoryData)
			.then(response => {
				const categories = response.data
				commit('UPDATE_BANNERCATEGORIES', { categoryData, rootState })
				return categories
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	setCurrentBannerId ({ commit }, id) {
		commit('SET_CURRENTBANNERID', id)
	},
	setConfigurationStatusId ({ commit }, id) {
		commit('SET_CONFIGURATIONSTATUSID', id)
	},

	// update configuration status id
	updateConfigurationStatusId ({ commit }, banner) {
		return configApi
			.put(
				'api/ConsentBanner/ConfigurationStatus?ConsentBannerId=' +
					banner.consentBannerId +
					'&ConfigurationStatusId=' +
					banner.configurationStatusId
			)
			.then(response => {
				const updatedConfigurationStatus = response.data

				commit('UPDATED_CONFIGURATIONSTATUS', banner)
				return updatedConfigurationStatus
			})
	},

	// color schemes
	getColourSchemes ({ commit }, typeId) {
		return new Promise((resolve, reject) => {
			getColourSchemes(typeId)
				.then(response => {
					const colorSchemes = response.data
					commit('SET_PREBANNERCOLORSCHEMES', colorSchemes)
					resolve(colorSchemes)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// get banner scripts
	getConsentBannerScripts ({ commit }, id) {
		return new Promise((resolve, reject) => {
			getConsentBannerScripts(id)
				.then(response => {
					let bannerScripts = response.data
					if (bannerScripts == null || bannerScripts === '') {
						bannerScripts = []
					}
					commit('SET_BANNERSCRIPTS', bannerScripts)
					resolve(bannerScripts)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	// get banner scripts
	getConsentBannerCisCookies ({ commit }, id) {
		return new Promise((resolve, reject) => {
			getConsentBannerCisCookies(id)
				.then(response => {
					const cisCookies = response.data

					commit('SET_BANNERCISCOOKIES', cisCookies)
					resolve(cisCookies)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// banner categories
	updateBannerScripts ({ commit, rootState }, scriptData) {
		return configApi
			.put('/api/ConsentBanner/ScriptConfiguration/', scriptData)
			.then(response => {
				const scripts = response.data
				commit('UPDATE_BANNERSCRIPTS', { scriptData, rootState })
				return scripts
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	// update cis cookies
	updateCisCookies ({ commit }, cisCookiesData) {
		return configApi
			.put('api/ConsentBanner/CisCookies/', cisCookiesData)
			.then(response => {
				const cisCookies = response.data

				commit('UPDATED_CISCOOKIES', cisCookies)
				return cisCookies
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	// profiles
	getConsentBannerProfiles ({ commit }, bannerId) {
		return new Promise((resolve, reject) => {
			getConsentBannerProfiles(bannerId)
				.then(response => {
					const profiles = response.data.profiles
					commit('SET_PROFILES', profiles)
					resolve(profiles)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	getConsentBannerProfile ({ commit }, getProfileObject) {
		const profileId = getProfileObject.selectedProfileId
		const consentBannerId = getProfileObject.selectedBannerId
		return new Promise((resolve, reject) => {
			getConsentBannerProfile(profileId, consentBannerId)
				.then(response => {
					const profile = response.data
					commit('SET_PROFILE', profile)
					resolve(profile)
					return profile
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	createConsentBannerProfile ({ commit }, profile) {
		return configApi
			.post('/api/ConsentBanner/CreateProfile', profile)
			.then(response => {
				const newProfile = profile
				newProfile.profileId = response.data.newConsentBannerId

				commit('CREATE_PROFILE', newProfile)

				return newProfile
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	updateConsentBannerProfile ({ commit }, updatedProfile) {
		return configApi
			.put('/api/ConsentBanner/UpdateProfile', updatedProfile)
			.then(response => {
				const profile = response.data
				commit('UPDATE_PROFILE', updatedProfile)
				return profile
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	deleteConsentBannerProfile ({ commit }, deleteProfileObject) {
		const profileId = deleteProfileObject.profileId
		const consentBannerId = deleteProfileObject.consentBannerId
		return configApi
			.delete(
				'/api/ConsentBanner/DeleteProfile?ConsentBannerId=' +
					consentBannerId +
					'&ProfileId=' +
					profileId
			)
			.then(response => {
				const deletedProfile = response.data
				commit('DELETE_PROFILE', profileId)
				return deletedProfile
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	// get embed code
	getEmbedCode ({ commit }, embedCodeObject) {
		const profileId = embedCodeObject.profileId
		const languageId = embedCodeObject.languageCode
		const consentBannerId = embedCodeObject.consentBannerId
		return new Promise((resolve, reject) => {
			getEmbedCode(profileId, languageId, consentBannerId)
				.then(response => {
					const embedCode = response.data
					commit('SET_EMBEDCODE', embedCode)
					resolve(embedCode)
					// return embedCode
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	publishConsentBannerProfile ({ commit }, profileIdToPublish) {
		return new Promise((resolve, reject) => {
			publishConsentBannerProfile(profileIdToPublish)
				.then(response => {
					const responseMessage = response.data
					commit('PUBLISH_PROFILE', responseMessage)
					resolve(responseMessage)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// Translations

	setTranslation ({ commit }, translation) {
		return configApi
			.post('/api/ConsentBanner/Translations', translation)
			.then(response => {
				const translation = response.data
				commit('CREATE_TRANSLATION', translation)

				return translation
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	getAllTranslations ({ commit }, bannerId) {
		return configApi
			.get('/api/ConsentBanner/Translations/' + bannerId)
			.then(response => {
				let translations = response.data
				if (translations.length === 0 || translations === null) {
					translations = []
				}
				commit('SET_TRANSLATIONS', translations)
				return translations
			})
			.catch(function (error) {
				throw error.response.data
			})
	},

	getTranslation ({ commit }, getTranslationObject) {
		const consentBannerId = getTranslationObject.consentBannerId
		const languageId = getTranslationObject.languageId
		return configApi
			.get(
				'api/ConsentBanner/Translation?consentBannerId=' +
					consentBannerId +
					'&LanguageId=' +
					languageId
			)
			.then(response => {
				const translation = response.data
				commit('SET_TRANSLATION', translation)
				return translation
			})
			.catch(function (error) {
				console.error(error)
			})
	},
	deleteTranslation ({ commit }, deleted) {
		const consentBannerId = deleted.consentBannerId
		const languageId = deleted.languageId
		return configApi
			.delete(
				'/api/ConsentBanner/Translation?ConsentBannerId=' +
					consentBannerId +
					'&LanguageId=' +
					languageId
			)
			.then(response => {
				const deletedTranslation = response.data
				commit('DELETE_TRANSLATION', deleted)
				return deletedTranslation
			})
			.catch(function (error) {
				throw error.response.data
			})
	},
	// Global setters
	setGlobalPreBannerHidden ({ commit }, preBannerHidden) {
		commit('SET_GLOBALPREBANNER', preBannerHidden)
	},

	setGpcEnabled ({ commit }, gpcValue) {
		commit('SET_GLOBALGPC', gpcValue.gpcEnabled)
	},
	setCisEnabled ({ commit }, cisValue) {
		commit('SET_GLOBALCIS', cisValue.cisEnabled)
	},

	// cookies preview
	setBannerPreviewData ({ commit }, previewData) {
		commit('SET_COOKIESPREVIEWDATA', previewData)
	}
}
