import { computed, ref } from '@vue/composition-api'

const NO_HEADER_TEXT_FILTER = ''
const NO_SELECTED_PRODUCT = null

const headerTextSearchQueryState = ref(NO_HEADER_TEXT_FILTER)
const selectedHeaderProductState = ref(NO_SELECTED_PRODUCT)

export const changeHeaderTextSearchQuery = searchQuery => {
	headerTextSearchQueryState.value = searchQuery
}
export const changeHeaderProduct = selectedProduct => {
	selectedHeaderProductState.value = selectedProduct
}

export const headerTextSearchQuery = computed({
	get () {
		return headerTextSearchQueryState.value
		// your getter
	},
	set (value) {
		changeHeaderTextSearchQuery(value)
		// your setter
	}
})

export const selectedHeaderProduct = computed({
	get () {
		return selectedHeaderProductState.value
		// your getter
	},
	set (value) {
		changeHeaderProduct(value)
		// your setter
	}
})

export const resetHeaderFilters = () => {
	changeHeaderTextSearchQuery(NO_HEADER_TEXT_FILTER)
	changeHeaderProduct(NO_SELECTED_PRODUCT)
}
