import { configApi } from '../http-client.js'

export const getAdminPortalUsers = () => {
	return configApi.get('api/AdminPortalUsers')
}

export const deleteAdminPortalUser = userId => configApi.delete(`api/AdminPortalUsers/${userId}`)

export const upsertAdminPortalUser = user => configApi.post('api/AdminPortalUsers/Upsert', user)

export const sendResetPasswordEmailApi = user => configApi.put('api/AdminPortalUsers/SendResetPasswordEmail', user)
