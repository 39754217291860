import { computed, ref } from '@vue/composition-api'

const NO_CHANNEL_SELECTED = null
const NO_CHANNEL_TEXT_FILTER = ''
const NO_CHANNEL_TYPE_SELECTED = null
const NO_CHANNEL_TRANSLATION_STATUS_SELECTED = null

const channelIdState = ref(NO_CHANNEL_SELECTED)
const channelTextSearchQueryState = ref(NO_CHANNEL_TEXT_FILTER)
const channelTypeIdState = ref(NO_CHANNEL_TYPE_SELECTED)
const channelTranslationStatusIdState = ref(NO_CHANNEL_TRANSLATION_STATUS_SELECTED)

export const changeChannelId = value => {
	channelIdState.value = value
}

export const changeChannelTextSearchQuery = searchQuery => {
	channelTextSearchQueryState.value = searchQuery
}

export const changeChannelTypeId = value => {
	channelTypeIdState.value = value
}

export const changeChannelTranslationStatusId = value => {
	channelTranslationStatusIdState.value = value
}

export const selectedChannelId = computed({
	get () {
		return channelIdState.value
		// your getter
	},
	set (value) {
		changeChannelId(value)
		// your setter
	}
})

export const channelTextSearchQuery = computed({
	get () {
		return channelTextSearchQueryState.value
		// your getter
	},
	set (value) {
		changeChannelTextSearchQuery(value)
		// your setter
	}
})

export const channelTypeid = computed({
	get () {
		return channelTypeIdState.value
		// your getter
	},
	set (value) {
		changeChannelTypeId(value)
		// your setter
	}
})

export const channelTranslationStatusId = computed({
	get () {
		return channelTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeChannelTranslationStatusId(value)
		// your setter
	}
})

export const resetChannelFilters = () => {
	changeChannelTextSearchQuery(NO_CHANNEL_TEXT_FILTER)
	changeChannelTypeId(NO_CHANNEL_TYPE_SELECTED)
	changeChannelTranslationStatusId(NO_CHANNEL_TRANSLATION_STATUS_SELECTED)
	changeChannelId(NO_CHANNEL_SELECTED)
}
