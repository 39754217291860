import { computed, ref } from '@vue/composition-api'

const NO_PROGRESSIVE_PROFILING_TEXT_FILTER = ''

const progressiveProfilingSearchQueryState = ref(NO_PROGRESSIVE_PROFILING_TEXT_FILTER)

export const changeProgressiveProfilingSearchQuery = searchQuery => {
	progressiveProfilingSearchQueryState.value = searchQuery
}

export const progressiveProfilingSearchQuery = computed({
	get () {
		return progressiveProfilingSearchQueryState.value
		// your getter
	},
	set (value) {
		changeProgressiveProfilingSearchQuery(value)
		// your setter
	}
})

export const resetProgressiveProfilingFilters = () => {
	changeProgressiveProfilingSearchQuery(NO_PROGRESSIVE_PROFILING_TEXT_FILTER)
}
