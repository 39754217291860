import Vue from 'vue'
import Vuex from 'vuex'
import adminPortalState from './modules/index.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		...adminPortalState
	},
	// Enable strict mode in development to get a warning
	// when mutating state outside of a mutation.
	// https://vuex.vuejs.org/guide/strict.html
	strict: process.env.NODE_ENV !== 'production'
})

export default store
