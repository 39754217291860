import { computed, ref } from '@vue/composition-api'
import { getConfigurationApi } from '../utils/api/configuration.js'

export const ADDRESS_DETAILS_GROUP = 'Address Details'
export const OTHER_DETAILS_GROUP = 'Other Details'
const configurationState = ref(null)

let isLoading = false
let isLoadingPromise = null

const load = async () => {
	if (isLoading) return
	isLoading = true
	isLoadingPromise = getConfigurationApi()
	const { data: configurationData } = await isLoadingPromise
	configurationState.value = configurationData
	isLoading = false
}

export const waitForLoad = async () => {
	if (isLoadingPromise === null) load()
	return isLoadingPromise
}

export const configuration = computed(() => {
	if (configurationState.value === null) {
		load()
		return {}
	}
	return configurationState.value
})

export const headerFooterCombinations = computed(() => configuration.value?.headerFooterCombinations || [])
export const responseChannels = computed(() => configuration.value?.responseChannels || [])
export const sourceCodes = computed(() => configuration.value?.sourceCodes || [])
export const channelRequiredStanDakFields = computed(() => {
	// flat children groups into one array
	const channels = configuration.value?.preferenceGroups?.parentGroups
		?.map(group => group.subGroups).flat()
		?.map(subGroup => subGroup.channels).flat()
	// add random requiredFields to each channel // Test data
	// const fieldList = ['title', 'forename', 'middleName', 'surname', 'postcode', 'custom1']
	// channels?.forEach(channel => {
	// 	channel.requiredStanDakFields = fieldList.filter(() => Math.random() < 0.8).join(',')
	// })
	// get all channels from children groups
	// get all required fields from channels
	const requiredFields = channels.filter(c => c.requiredStandDakFields?.length > 0)
		?.map(channel => {
			return { requiredFields: channel.requiredStandDakFields?.split(',').map(x => x.toLowerCase()) || [], channelId: channel.channelId }
		})
	return requiredFields || []
})

export const searchFieldFilter = field => ['syrenisid', 'email1', 'email2', 'telephone1', 'telephone2', 'mobile', 'urn1', 'urn2',
	'urn3', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5', 'custom6', 'custom7', 'custom8', 'custom9', 'custom10', 'forename', 'surname', 'postcode']
	.includes(field.toLowerCase())

export const dataSubjectSearchFields = computed(() => {
	return configuration.value?.dataSubjectSearchFieldsList
		? configuration.value?.dataSubjectSearchFieldsList.filter(({ isSearchableBy, fieldName }
		) => isSearchableBy && searchFieldFilter(fieldName)).sort((a, b) => {
			return a.displayOrder - b.displayOrder
		})
		: []
})
export const showProfileTable = computed(() =>
	configuration.value?.dataSubjectSearchFieldsList
		.some(({ showInProfilesTable }) => showInProfilesTable === true))
export const profileTableFields = computed(() =>
	configuration.value?.dataSubjectSearchFieldsList
		?.filter(({ showInProfilesTable }) => showInProfilesTable === true) ?? [])
export const profileTableHeaders = computed(() => {
	const headers = configuration.value?.dataSubjectSearchFieldsList
		?.filter(({ showInProfilesTable }) => showInProfilesTable === true)
		?.map(({ fieldName, fieldLabel }) => ({
			text: fieldLabel || fieldName,
			value: fieldName
		}))
	headers.push({ text: 'Current Selection', value: 'currentSelection' })
	return headers
}
)
export const dataSubjectSearchResults = computed(() => {
	return configuration.value?.dataSubjectSearchFieldsList
		? configuration.value?.dataSubjectSearchFieldsList.filter(({ includeInSearchResults }) => includeInSearchResults).sort((a, b) => {
			return a.searchResultsDisplayOrder - b.searchResultsDisplayOrder
		})
		: []
})
const basicConfig = computed(() => configuration.value?.basicConfig || {})
export const customLogoutUrl = computed(() => basicConfig.value.customLogoutUrl)
export const brandFieldLabel = computed(() => basicConfig.value.brandFieldLabel)
export const showSendPrefConfirmationEmail = computed(() => basicConfig.value.showSendPrefConfirmationEmail)
export const sendPrefConfirmationEmailDefaultState = computed(() => basicConfig.value.sendPrefConfirmationEmailDefaultState)
export const showResponseChannelForPrefSubmissions = computed(() => basicConfig.value.showResponseChannelForPrefSubmissions)
export const responseChannelForSubmissionsMandatory = computed(() => basicConfig.value.responseChannelForSubmissionsMandatory)
export const showSourceCodeForPrefSubmissions = computed(() => basicConfig.value.showSourceCodeForPrefSubmissions)
export const sourceCodeForSubmissionsMandatory = computed(() => basicConfig.value.sourceCodeForSubmissionsMandatory)
export const showHeaderFooterForPrefSubmissions = computed(() => basicConfig.value.showHeaderFooterForPrefSubmissions)
export const headerFooterForSubmissionsMandatory = computed(() => basicConfig.value.headerFooterForSubmissionsMandatory)
export const headerFooterFieldLabel = computed(() => basicConfig.value.headerFooterFieldLabel)
export const showPostcodeLookup = computed(() => basicConfig.value.showPostCodeLookup)
export const contactDetailsFields = computed(() => configuration.value.contactDetailsFields || [])
export const responseChannelFieldLabel = computed(() => basicConfig.value.responseChannelFieldLabel)
export const sourceCodeFieldLabel = computed(() => basicConfig.value.sourceCodeFieldLabel)
export const canCreateCsPortalUsers = computed(() => basicConfig.value.canCreateCSPortalUsers)
export const canCreateDataSubjects = computed(() => basicConfig.value.canCreateDataSubjects)
export const masterCanEditContactDetails = computed(() => basicConfig.value.canEditContactDetails)
export const masterCanEditPreferences = computed(() => basicConfig.value.canEditPreferences)
export const groupedContactDetailsFields = computed(() => {
	const map = {
		'Personal Details': ['title', 'forename', 'middlename', 'surname'],
		Identifiers: ['syrenisid', 'urn1', 'urn2', 'urn3'],
		[ADDRESS_DETAILS_GROUP]: ['address1', 'address2', 'address3', 'address4', 'address5', 'town', 'county', 'postcode', 'country'],
		'Email and Phone Details': ['email1', 'email2', 'telephone1', 'telephone2', 'mobile'],
		'Other Details': ['organisationname', 'jobtitle', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5', 'custom6', 'custom7', 'custom8', 'custom9', 'custom10']
	}
	Object.keys(map).forEach(group => {
		const fields = map[group].map(fieldName => contactDetailsFields.value.find(field => field.fieldName.toLowerCase() === fieldName)
		).filter(field => field?.include)
		if (fields.length) {
			map[group] = fields
		} else {
			delete map[group]
		}
	})
	return map
})
export const preferenceGroups = computed(() => {
	return (configuration.value?.preferenceGroups?.parentGroups || []).map(parentGroup => ({
		...parentGroup,
		subGroups: parentGroup.subGroups.filter(({ display }) => display).map(subGroup => ({
			...subGroup,
			channels: subGroup.channels
				.filter(({ subGroupId }) => subGroupId !== 0 &&
					subGroupId !== null).map(channel => {
					channel.extendedPrefs = channel.extendedPrefs.map(extendedPref => ({
						...extendedPref,
						availableValuesIfDropdown: configuration.value?.availableValuesIfDropdown?.filter(x =>
							x.extendedPreferenceKeyId === extendedPref.keyId
						)
					}))
					return channel
				})
		}))
	}))
})
export const onlyShowDataSubjectsAssociatedWithSelectedBrand = computed(() => basicConfig.value.onlyShowDataSubjectsAssociatedWithSelectedBrand)
export const auditHistorySortNewestToOldest = computed(() => basicConfig.value.auditHistorySortNewestToOldest)
export const auditHistoryExpandedByDefault = computed(() => basicConfig.value.auditHistoryExpandedByDefault)
export const showValidFromAndToFieldsForPrefSubmissions = computed(() => basicConfig.value.showValidFromAndToFieldsForPrefSubmissions)
export const canEditValidFromAndToFieldsForPrefSubmissions = computed(() => basicConfig.value.canEditValidFromAndToFieldsForPrefSubmissions)
export const privacyPolicyMandatory = computed(() => basicConfig.value.privacyPolicyMandatory)
export const dataSubjectsWithNoPreferencesTickedByDefault = computed(() => basicConfig.value.dataSubjectsWithNoPreferencesTickedByDefault)
