import { computed, ref } from '@vue/composition-api'

const NO_API_ACCESS_KEY_TEXT_FILTER = ''
const NO_COOKIE_ACCESS_KEY_TEXT_FILTER = ''
const NO_PREFERENCE_WIDGET_ACCESS_KEY_TEXT_FILTER = ''

const apiAccessKeySearchQueryState = ref(NO_API_ACCESS_KEY_TEXT_FILTER)
const cookieAccessKeySearchQueryState = ref(NO_COOKIE_ACCESS_KEY_TEXT_FILTER)
const preferenceWidgetAccessKeySearchQueryState = ref(NO_PREFERENCE_WIDGET_ACCESS_KEY_TEXT_FILTER)

export const changeApiAccessKeySearchQuery = searchQuery => {
	apiAccessKeySearchQueryState.value = searchQuery
}

export const changeCookieAccessKeySearchQuery = searchQuery => {
	cookieAccessKeySearchQueryState.value = searchQuery
}

export const changePreferenceWidgetAccessKeySearchQuery = searchQuery => {
	preferenceWidgetAccessKeySearchQueryState.value = searchQuery
}

export const apiAccessKeySearchQuery = computed({
	get () {
		return apiAccessKeySearchQueryState.value
		// your getter
	},
	set (value) {
		changeApiAccessKeySearchQuery(value)
		// your setter
	}
})

export const cookieAccessKeySearchQuery = computed({
	get () {
		return cookieAccessKeySearchQueryState.value
		// your getter
	},
	set (value) {
		changeCookieAccessKeySearchQuery(value)
		// your setter
	}
})

export const preferenceWidgetAccessKeySearchQuery = computed({
	get () {
		return preferenceWidgetAccessKeySearchQueryState.value
		// your getter
	},
	set (value) {
		changePreferenceWidgetAccessKeySearchQuery(value)
		// your setter
	}
})

export const resetAccessKeyFilters = () => {
	changeApiAccessKeySearchQuery(NO_API_ACCESS_KEY_TEXT_FILTER)
	changeCookieAccessKeySearchQuery(NO_COOKIE_ACCESS_KEY_TEXT_FILTER)
	changePreferenceWidgetAccessKeySearchQuery(NO_PREFERENCE_WIDGET_ACCESS_KEY_TEXT_FILTER)
}
