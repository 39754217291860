import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_SCRIPT_NAME_FILTER = ''
const NO_SELECTED_TYPE = null
const NO_SELECTED_COOKIE_CATEGORY = null

const cookieScriptSearchQueryState = ref(NO_COOKIE_SCRIPT_NAME_FILTER)
const selectedCookieScriptTypeState = ref(NO_SELECTED_TYPE)
const selectedCookieCategoryState = ref(NO_SELECTED_COOKIE_CATEGORY)

export const changeCookieScriptSearchQuery = searchQuery => {
	cookieScriptSearchQueryState.value = searchQuery
}
export const changeCookieScriptType = selectedType => {
	selectedCookieScriptTypeState.value = selectedType
}
export const changeCookieCategory = selectedCookieCategory => {
	selectedCookieCategoryState.value = selectedCookieCategory
}

export const cookieScriptSearchQuery = computed({
	get () {
		return cookieScriptSearchQueryState.value
	},
	set (value) {
		changeCookieScriptSearchQuery(value)
	}
})

export const selectedCookieScriptType = computed({
	get () {
		return selectedCookieScriptTypeState.value
	},
	set (value) {
		changeCookieScriptType(value)
	}
}
)
export const selectedCookieCategory = computed({
	get () {
		return selectedCookieCategoryState.value
	},
	set (value) {
		changeCookieCategory(value)
	}
})

export const resetCookieScriptFilters = () => {
	changeCookieScriptSearchQuery(NO_COOKIE_SCRIPT_NAME_FILTER)
	changeCookieScriptType(NO_SELECTED_TYPE)
	changeCookieCategory(NO_SELECTED_COOKIE_CATEGORY)
}
