import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	logo: {}
}

export const getters = {
	logoUrl (state) {
		return state.logo.logoUrl
	}
}

export const mutations = {
	UPLOAD_LOGO (state, logo) {
		state.logo = logo
	}
}

export const actions = {
	uploadBannerLogo ({ commit }, formData) {
		return configApi
			.post('/api/Assets/UploadLargeFile', formData)
			.then(response => {
				const logoUrl = response.data

				commit('UPLOAD_LOGO', logoUrl)

				return logoUrl
			})
			.catch(function (error) {
				throw error.response.data
			})
	}
}
