import { computed, ref } from '@vue/composition-api'

const NO_STATEMENT_TEXT_FILTER = ''

const statementTextSearchQueryState = ref(NO_STATEMENT_TEXT_FILTER)

export const changeStatementTextSearchQuery = searchQuery => {
	statementTextSearchQueryState.value = searchQuery
}

export const statementTextSearchQuery = computed({
	get () {
		return statementTextSearchQueryState.value
		// your getter
	},
	set (value) {
		changeStatementTextSearchQuery(value)
		// your setter
	}
})

export const resetStatementFilters = () => {
	changeStatementTextSearchQuery(NO_STATEMENT_TEXT_FILTER)
}
