<template>
	<v-navigation-drawer
		permanent
		width="260"
		color="nav-bar-background"
		dark
	>
		<v-img
			max-width="100"
			src="../assets/cassie-logo-white.svg"
			class="mt-6 mb-6 ml-4"
		/>
		<v-list
			nav
			dense
			expand
		>
			<template v-for="link in links">
				<v-list-item
					v-if="!link.sublinks || link.sublinks.length === 0"
					:key="link.text"
					:to="link.customAction ? '' : link.route"
					link
					@click="triggerCustomAction(link.customAction)"
				>
					<v-list-item-title
						class="indigo--text text--lighten-5 font-weight-light"
					>
						<v-icon
							class="mr-3 indigo--text text--lighten-5"
							dense
						>
							{{ link.icon }}
						</v-icon>

						{{ link.text | useLabels }}
					</v-list-item-title>
				</v-list-item>
				<v-list-group
					v-else
					:key="link.text"
					link
				>
					<v-list-item-content
						slot="activator"
						color="indigo"
					>
						<v-list-item-title
							class="indigo--text text--lighten-5  font-weight-light"
						>
							<v-icon
								class="mr-3 indigo--text text--lighten-5"
								dense
							>
								{{ link.icon }}
							</v-icon>

							{{ link.text | useLabels }}
						</v-list-item-title>
					</v-list-item-content>
					<template v-for="sublink in link.sublinks">
						<v-list-item
							v-if="!sublink.deeperSublinks || sublink.deeperSublinks.length == 0"
							:key="sublink.text"
							:to="sublink.customAction ? '' : sublink.route"
							link
							@click="triggerCustomAction(sublink.customAction)"
						>
							<v-list-item-title
								class="indigo--text text--lighten-5 ml-5 font-weight-light"
							>
								{{ sublink.text | useLabels }}
							</v-list-item-title>
						</v-list-item>
						<v-list-group
							v-else
							:key="sublink.text"
						>
							<v-list-item-content
								slot="activator"
								color="indigo"
							>
								<v-list-item-title
									class="indigo--text text--lighten-5 ml-5 font-weight-light"
								>
									{{ sublink.text | useLabels }}
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item
								v-for="deeperSublink in sublink.deeperSublinks"
								:key="deeperSublink.text"
								:to="deeperSublink.customAction ? '' : deeperSublink.route"
								link
								@click="triggerCustomAction(deeperSublink.customAction)"
							>
								<v-list-item-title
									class="indigo--text text--lighten-5 ml-10 font-weight-light"
								>
									{{ deeperSublink.text | useLabels }}
								</v-list-item-title>
							</v-list-item>
						</v-list-group>
					</template>
				</v-list-group>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	props: {
		links: {
			type: Array,
			default: () => []
		},
		changeShowFilters: {
			type: Function,
			default: () => {}
		},
		resetAdminPortalFilters: {
			type: Function,
			default: () => {}
		}
	},
	methods: {
		triggerCustomAction (action) {
			this.resetFilters()
			if (!action) return
			action()
		},
		resetFilters () {
			this.changeShowFilters(false)
			this.resetAdminPortalFilters()
		}
	}
}
</script>
