import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	privacyPolicies: []
}

export const getters = {
	privacyPolicies (state) {
		return state.privacyPolicies
	}
}

export const mutations = {
	SET_PRIVACYPOLICIES (state, privacyPolicies) {
		const privacyPoliciesOrdered = privacyPolicies
		privacyPoliciesOrdered.sort((a, b) => a.text.localeCompare(b.text))
		state.privacyPolicies = privacyPoliciesOrdered
	}
}

export const actions = {
	getPrivacyPolicies ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/PrivacyPolicies')
				.then(response => {
					const privacyPolicies = response.data.map(privacyPolicy => ({
						brandId: privacyPolicy.brandId,
						brandName: privacyPolicy.brandName,
						isGlobalDefault: privacyPolicy.isGlobalDefault,
						isVisibleInCSPortal: privacyPolicy.isVisibleInCSPortal,
						privacyPolicyId: privacyPolicy.privacyPolicyId,
						value: privacyPolicy.privacyPolicyId,
						text: privacyPolicy.versions.find(({ isActive }) => isActive).policyName,
						policyUrl: privacyPolicy.versions.find(({ isActive }) => isActive).policyUrl
					}))

					commit('SET_PRIVACYPOLICIES', privacyPolicies)
					resolve(privacyPolicies)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
