import { camelCase } from 'lodash/string.js'
import { configApi } from '../http-client.js'

export const getConfigurationApi = () => {
	return configApi.get('/CSPortal/api/Config/ConfigFile').then(({ data }) => ({
		data: {
			...data,
			contactDetailsFields: data.contactDetailsFields.map(datum => {
				return {
					...datum,
					fieldName: camelCase(datum.fieldName),
					allowedValues: datum.allowedValues && datum.fieldType === 2 ? datum.allowedValues.split(',') : [datum.allowedValues] || []
				}
			}),
			dataSubjectSearchFieldsList: data.dataSubjectSearchFieldsList.map(datum => ({
				...datum,
				fieldName: camelCase(datum.fieldName)
			}))
		}
	}))
}

export const putConfigurationApi = configuration => {
	const config = {
		basicConfig: {
			brandFieldLabel: configuration.basicConfig.brandFieldLabel ?? '',
			onlyShowDataSubjectsAssociatedWithSelectedBrand: configuration.basicConfig.onlyShowDataSubjectsAssociatedWithSelectedBrand,
			showHeaderFooterForPrefSubmissions: false,
			canCreateCSPortalUsers: configuration.basicConfig.canCreateCSPortalUsers,
			canCreateDataSubjects: configuration.basicConfig.canCreateDataSubjects,
			canEditPreferences: configuration.basicConfig.canEditPreferences,
			canEditContactDetails: configuration.basicConfig.canEditContactDetails,
			headerFooterForSubmissionsMandatory: false,
			headerFooterFieldLabel: configuration.basicConfig.headerFooterFieldLabel ?? '',
			showResponseChannelForPrefSubmissions: configuration.basicConfig.showResponseChannelForPrefSubmissions,
			responseChannelForSubmissionsMandatory: configuration.basicConfig.responseChannelForSubmissionsMandatory,
			responseChannelFieldLabel: configuration.basicConfig.responseChannelFieldLabel ?? '',
			showSourceCodeForPrefSubmissions: configuration.basicConfig.showSourceCodeForPrefSubmissions,
			sourceCodeForSubmissionsMandatory: configuration.basicConfig.sourceCodeForSubmissionsMandatory,
			sourceCodeFieldLabel: configuration.basicConfig.sourceCodeFieldLabel ?? '',
			showPostCodeLookup: configuration.basicConfig.showPostCodeLookup,
			auditHistorySortNewestToOldest: configuration.basicConfig.auditHistorySortNewestToOldest,
			auditHistoryExpandedByDefault: configuration.basicConfig.auditHistoryExpandedByDefault,
			showValidFromAndToFieldsForPrefSubmissions: configuration.basicConfig.showValidFromAndToFieldsForPrefSubmissions,
			canEditValidFromAndToFieldsForPrefSubmissions: configuration.basicConfig.canEditValidFromAndToFieldsForPrefSubmissions,
			privacyPolicyMandatory: configuration.basicConfig.privacyPolicyMandatory,
			customLogoutUrl: configuration.basicConfig.customLogoutUrl ?? '',
			dataSubjectsWithNoPreferencesTickedByDefault: configuration.basicConfig.dataSubjectsWithNoPreferencesTickedByDefault
		},
		dataSubjectSearchFields: configuration.dataSubjectSearchFieldsList,
		contactDetailsFields: configuration.contactDetailsFields.map(contactDetailsField => {
			return {
				...contactDetailsField,
				allowedValues: contactDetailsField.allowedValues.join()
			}
		})
	}
	config.basicConfig.headerFooterCombinations = configuration.headerFooterCombinations
	config.basicConfig.responseChannels = configuration.responseChannels
	config.basicConfig.sourceCodes = configuration.sourceCodes.map(sourceCode => {
		return {
			brandId: sourceCode.brandId,
			isDefault: sourceCode.isDefault,
			sourceCodeId: sourceCode.sourceCodeId,
			sourceCodeName: sourceCode.sourceCodeName
		}
	})
	config.preferenceGroups = {
		parentGroups: configuration.preferenceGroups.parentGroups.map((group, index) => {
			return {
				...group,
				displayOrder: index + 1,
				subGroups: group.subGroups.map((subGroup, index) => {
					return {
						subGroupId: subGroup.subGroupId,
						parentGroupId: subGroup.parentGroupId,
						subGroupName: subGroup.subGroupName,
						displayOrder: index + 1,
						display: subGroup.display,
						hideNoPref: subGroup.hideNoPref,
						canBeAdded: subGroup.canBeAdded,
						expandedByDefault: subGroup.expandedByDefault,
						channels: subGroup.channels.map(channel => {
							return {
								id: channel.id,
								subGroupId: channel.subGroupId,
								channelId: channel.channelId,
								displayOrderInSubGroup: channel.displayOrderInSubGroup,
								display: channel.display,
								hideNoPref: channel.hideNoPref,
								canBeAdded: channel.canBeAdded,
								requiredStandDakFields: channel.requiredStandDakFields,
								extendedPrefs: channel.extendedPrefs.map(extendedPref => {
									return {
										extendedPreferenceKeyId: extendedPref.keyId,
										extendedPreferenceKeyLabel: extendedPref.keyLabel,
										hideFromUi: extendedPref.hideFromUi,
										isMandatory: extendedPref.isMandatory,
										isEditable: extendedPref.isEditable,
										extendedPreferenceFieldTypeId: extendedPref.fieldTypeId,
										defaultFreeTextValue: extendedPref.defaultFreeTextValue,
										defaultSingleSelectAvailableValueId: extendedPref.defaultSingleSelectValueId
									}
								})
							}
						})
					}
				})
			}
		})
	}
	return configApi.put('/CSPortal/api/Config', config)
}

export const getStandakFields = () => {
	return configApi.get('/api/GetDataSubjectContactFields').then(({ data: { standakFields } }) => ({ data: standakFields }))
}
