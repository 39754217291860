import { computed, ref } from '@vue/composition-api'

// system integrations
const NO_SYSTEM_INTEGRATION_TEXT_FILTER = ''

const systemIntegrationSearchQueryState = ref(NO_SYSTEM_INTEGRATION_TEXT_FILTER)

export const changeSystemIntegrationSearchQuery = searchQuery => {
	systemIntegrationSearchQueryState.value = searchQuery
}

export const systemIntegrationSearchQuery = computed({
	get () {
		return systemIntegrationSearchQueryState.value
		// your getter
	},
	set (value) {
		changeSystemIntegrationSearchQuery(value)
		// your setter
	}
})

// integration services
const NO_INTEGRATION_SERVICE_TEXT_FILTER = ''
const NO_INTEGRATION_SERVICE_ENABLED_STATUS_FILTER = null

const integrationServiceSearchQueryState = ref(NO_INTEGRATION_SERVICE_TEXT_FILTER)
const integrationServiceEnabledStatusFilterState = ref(NO_INTEGRATION_SERVICE_ENABLED_STATUS_FILTER)

export const changeIntegrationServiceSearchQuery = searchQuery => {
	integrationServiceSearchQueryState.value = searchQuery
}

export const changeIntegrationServiceEnabledStatusFilter = enabledStatusFilter => {
	integrationServiceEnabledStatusFilterState.value = enabledStatusFilter
}

export const integrationServiceSearchQuery = computed({
	get () {
		return integrationServiceSearchQueryState.value
		// your getter
	},
	set (value) {
		changeIntegrationServiceSearchQuery(value)
		// your setter
	}
})

export const integrationServiceEnabledStatusFilter = computed({
	get () {
		return integrationServiceEnabledStatusFilterState.value
		// your getter
	},
	set (value) {
		changeIntegrationServiceEnabledStatusFilter(value)
		// your setter
	}
})

export const resetConnectorServiceFilters = () => {
	changeSystemIntegrationSearchQuery(NO_SYSTEM_INTEGRATION_TEXT_FILTER)
	changeIntegrationServiceSearchQuery(NO_INTEGRATION_SERVICE_TEXT_FILTER)
	changeIntegrationServiceEnabledStatusFilter(NO_INTEGRATION_SERVICE_ENABLED_STATUS_FILTER)
}
