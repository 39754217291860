import { configApi } from '../../../shared/utils/http-client.js'
const getConsentBanner = () => {
	return configApi.get('/api/ConsentBanner/')
}
const getBasicDetails = id => {
	return configApi.get('/api/ConsentBanner/BasicDetails/' + id)
}
const getColourSchemes = typeId => {
	return configApi.get('/api/ConsentBanner/ColourSchemePreSets/' + typeId)
}
const getConsentBannerPreBanner = id => {
	return configApi.get('/api/ConsentBanner/PreBannerConfiguration/' + id)
}
const getConsentBannerConfiguration = id => {
	return configApi.get('api/ConsentBanner/Configuration/' + id)
}
const getConsentBannerScripts = id => {
	return configApi.get('api/ConsentBanner/ScriptConfiguration/' + id)
}
const getConsentBannerCisCookies = id => {
	return configApi.get('api/ConsentBanner/CisCookies?consentBannerId=' + id)
}
const getConsentBannerConfigurationStatus = id => {
	return configApi.get('api/ConsentBanner/ConfigurationStatus/' + id)
}

const getConsentBannerProfiles = id => {
	return configApi.get('api/ConsentBanner/Profiles/' + id)
}

const getConsentBannerProfile = (profileId, consentBannerId) => {
	return configApi.get(
		'api/ConsentBanner/Profiles?ProfileId=' +
      profileId +
      '&ConsentBannerId=' +
      consentBannerId
	)
}

const getEmbedCode = (profileId, languageCode, consentBannerId) => {
	return configApi.get(
		'api/ConsentBanner/EmbedCode?ProfileId=' +
      profileId +
      '&LanguageCode=' +
      languageCode +
      '&ConsentBannerId=' +
      consentBannerId
	)
}

const publishConsentBannerProfile = profileId => {
	return configApi.get('api/ConsentBanner/Profiles/Publish/' + profileId)
}

export {
	getConsentBanner,
	getBasicDetails,
	getConsentBannerConfigurationStatus,
	getConsentBannerConfiguration,
	getConsentBannerScripts,
	getConsentBannerCisCookies,
	getColourSchemes,
	getConsentBannerPreBanner,
	getConsentBannerProfiles,
	getConsentBannerProfile,
	getEmbedCode,
	publishConsentBannerProfile
}
