import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	accessKeys: []
}

export const getters = {
	accessKeys (state) {
		return state.accessKeys
	}
}

export const mutations = {
	SET_ACCESSKEYS (state, accessKeys) {
		const accessKeysOrdered = accessKeys
		accessKeysOrdered.sort((a, b) =>
			a.accessKeyName.localeCompare(b.accessKeyName)
		)
		state.accessKeys = accessKeysOrdered
	}
}

export const actions = {
	getConsentBannerAccessKeys ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/AccessKeys/ConsentBanner')
				.then(response => {
					const accessKeys = response.data
					commit('SET_ACCESSKEYS', accessKeys)
					resolve(accessKeys)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
