<template>
	<v-btn
		v-bind="$props"
		color="blue darken-2"
		class="px-10"
		:class="getQaSelectorClass()"
		outlined
		:data-testid="testId"
		v-on="$listeners"
	>
		<slot />
	</v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'secondary-action-button',
	extends: VBtn,
	props: {
		testId: {
			type: String,
			default: 'cassie_secondaryactionbutton'
		}
	},
	methods: {
		getQaSelectorClass () {
			const buttonText = this.$slots.default.find(({ text }) => text).text.trim().replace(/\s/g, '-').toLowerCase()
			return `qa-primaryactionbutton-${buttonText}`
		}
	}
}
</script>
