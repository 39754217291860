export const USER_MANAGEMENT = 'user-management'
export const USER_MANAGEMENT_CREATE_USER = 'user-management-create-user'
export const USER_MANAGEMENT_EDIT_USER = 'user-management-edit-user'
export const CS_PORTAL_PRODUCT_ROLES = 'product-roles'
export const CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE = 'product-roles-create-role'
export const CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE = 'product-roles-edit-role'
export const ADMIN_PORTAL_PRODUCT_ROLES = 'admin-portal-product-roles'
export const ADMIN_PORTAL_PRODUCT_ROLES_CREATE_ROLE = 'admin-portal-product-roles-create-role'
export const ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE = 'admin-portal-product-roles-edit-role'
export const SESSION_EXPIRED = 'session-expired'
