import { computed, ref } from '@vue/composition-api'

const NO_EXTENDED_PREFERENCES_TEXT_FILTER = ''

const extendedPreferencesSearchQueryState = ref(NO_EXTENDED_PREFERENCES_TEXT_FILTER)

export const changeExtendedPreferencesSearchQuery = searchQuery => {
	extendedPreferencesSearchQueryState.value = searchQuery
}

export const extendedPreferencesSearchQuery = computed({
	get () {
		return extendedPreferencesSearchQueryState.value
		// your getter
	},
	set (value) {
		changeExtendedPreferencesSearchQuery(value)
		// your setter
	}
})

export const resetExtendedPreferencesFilters = () => {
	changeExtendedPreferencesSearchQuery(NO_EXTENDED_PREFERENCES_TEXT_FILTER)
}
