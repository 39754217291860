import { computed, ref } from '@vue/composition-api'

const NO_SYSTEM_EMAIL_TEXT_FILTER = ''
const NO_SYSTEM_EMAIL_TYPE_FILTER = null
const NO_SYSTEM_EMAIL_TRANSLATION_STATUS_SELECTED = null

const systemEmailSearchQueryState = ref(NO_SYSTEM_EMAIL_TEXT_FILTER)
const systemEmailTypeFilterState = ref(NO_SYSTEM_EMAIL_TYPE_FILTER)
const systemEmailTranslationStatusIdState = ref(NO_SYSTEM_EMAIL_TRANSLATION_STATUS_SELECTED)

export const changeSystemEmailSearchQuery = searchQuery => {
	systemEmailSearchQueryState.value = searchQuery
}
export const changeSystemEmailTypeFilter = typeFilter => {
	systemEmailTypeFilterState.value = typeFilter
}
export const changeSystemEmailTranslationStatusId = value => {
	systemEmailTranslationStatusIdState.value = value
}

export const systemEmailSearchQuery = computed({
	get () {
		return systemEmailSearchQueryState.value
		// your getter
	},
	set (value) {
		changeSystemEmailSearchQuery(value)
		// your setter
	}
})

export const systemEmailTypeFilter = computed({
	get () {
		return systemEmailTypeFilterState.value
		// your getter
	},
	set (value) {
		changeSystemEmailTypeFilter(value)
		// your setter
	}
})

export const systemEmailTranslationStatusId = computed({
	get () {
		return systemEmailTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeSystemEmailTranslationStatusId(value)
		// your setter
	}
})

export const resetSystemEmailFilters = () => {
	changeSystemEmailSearchQuery(NO_SYSTEM_EMAIL_TEXT_FILTER)
	changeSystemEmailTypeFilter(NO_SYSTEM_EMAIL_TYPE_FILTER)
	changeSystemEmailTranslationStatusId(NO_SYSTEM_EMAIL_TRANSLATION_STATUS_SELECTED)
}
