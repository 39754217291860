import { configApi } from '../http-client.js'

export const getAdminPortalNavbarItems = () => {
	return configApi.get('/api/AdminPortalNavigation').then(({ data: { adminPortalNavigation } }) => ({
		adminPortalNavigation: adminPortalNavigation.map(topLevelNavItem => ({
			adminPortalNavigationId: topLevelNavItem.adminPortalNavigationId,
			text: topLevelNavItem.text,
			displayText: topLevelNavItem.displayText,
			icon: topLevelNavItem.icon,
			isExternal: topLevelNavItem.isExternal,
			route: !topLevelNavItem.isExternal ? { name: topLevelNavItem.route || '' } : topLevelNavItem.route || '',
			sublinks: topLevelNavItem.sublinks.length >= 1
				? topLevelNavItem.sublinks.map(subLinkNavItem => ({
					adminPortalNavigationId: subLinkNavItem.adminPortalNavigationId,
					text: subLinkNavItem.text,
					displayText: subLinkNavItem.displayText,
					icon: subLinkNavItem.icon,
					isExternal: subLinkNavItem.isExternal,
					route: !subLinkNavItem.isExternal ? { name: subLinkNavItem.route || '' } : subLinkNavItem.route || '',
					deeperSublinks: subLinkNavItem.deeperSublinks.length >= 1
						? subLinkNavItem.deeperSublinks.map(deeperSublinkItem => ({
							adminPortalNavigationId: deeperSublinkItem.adminPortalNavigationId,
							text: deeperSublinkItem.text,
							displayText: deeperSublinkItem.displayText,
							icon: deeperSublinkItem.icon,
							isExternal: deeperSublinkItem.isExternal,
							route: !deeperSublinkItem.isExternal ? { name: deeperSublinkItem.route || '' } : deeperSublinkItem.route || ''
						}))
						: []
				}))
				: []
		}))
	}))
}
