import { ref } from '@vue/composition-api'

export const validationObservers = ref([])

export const registerValidationForm = validationObserver => {
	validationObservers.value.push(validationObserver)
}

export const unregisterValidationForm = uid => {
	validationObservers.value = validationObservers.value.filter(({ _uid }) => _uid !== uid)
}
