import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_POLICY_TABLES_TEXT_FILTER = ''

const cookiePolicyTablesSearchQueryState = ref(NO_COOKIE_POLICY_TABLES_TEXT_FILTER)

export const changeCookiePolicyTablesSearchQuery = searchQuery => {
	cookiePolicyTablesSearchQueryState.value = searchQuery
}

export const cookiePolicyTablesSearchQuery = computed({
	get () {
		return cookiePolicyTablesSearchQueryState.value
		// your getter
	},
	set (value) {
		changeCookiePolicyTablesSearchQuery(value)
		// your setter
	}
})

export const resetCookiePolicyTablesFilters = () => {
	changeCookiePolicyTablesSearchQuery(NO_COOKIE_POLICY_TABLES_TEXT_FILTER)
}
