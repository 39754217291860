import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_APPEARANCE_TEMPLATE_NAME_FILTER = ''

const cookieAppearanceTemplateSearchQueryState = ref(NO_COOKIE_APPEARANCE_TEMPLATE_NAME_FILTER)

export const changeCookieAppearanceTemplateSearchQuery = searchQuery => {
	cookieAppearanceTemplateSearchQueryState.value = searchQuery
}

export const cookieAppearanceTemplateSearchQuery = computed({
	get () {
		return cookieAppearanceTemplateSearchQueryState.value
	},
	set (value) {
		changeCookieAppearanceTemplateSearchQuery(value)
	}
})

export const resetCookieAppearanceTemplateFilters = () => {
	changeCookieAppearanceTemplateSearchQuery(NO_COOKIE_APPEARANCE_TEMPLATE_NAME_FILTER)
}
