import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	configKeys: [],
	IsCISVisibleInAdminPortal: {}
}

export const getters = {
	configKeys (state) {
		return state.configKeys
	},
	cisVisibleInAdminPortal (state) {
		return state.IsCISVisibleInAdminPortal.keyValue
	}
}

export const mutations = {
	SET_CONFIGKEYS (state, configKeys) {
		state.configKeys = configKeys

		state.IsCISVisibleInAdminPortal = configKeys.find(cis => {
			return cis.keyName === 'IsCISVisibleInAdminPortal'
		})
	}
}

export const actions = {
	getConfigKeys ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/ConfigKeys')
				.then(response => {
					const configKeys = response.data
					commit('SET_CONFIGKEYS', configKeys)
					resolve(configKeys)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
