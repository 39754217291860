<template>
	<Modal width="500px">
		<template #modal-title>
			{{ title }}
		</template>
		<template #modal-content>
			<div class="modal-text">
				We can see that you've been inactive. To keep your account secure, you'll automatically be logged out in <span class="font-weight-bold">
					{{ countdown }}
				</span> seconds. If you're not finished with what you're doing, please click 'Keep me logged in' below or alternatively log out.
			</div>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="logOut">
				Log out
			</SecondaryActionButton>
			<PrimaryActionButton @click="keepLoggedIn">
				Keep me logged in
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../shared/components/modal.vue'
import PrimaryActionButton from '../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../shared/components/secondary-action-button.vue'

export default {
	components: {
		Modal,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		title: String,
		countdown: Number,
		keepLoggedIn: Function,
		logOut: Function
	},
	data () {
		return {
			dialog: true
		}
	},
	watch: {
		dialog (val) {
			if (!val) {
				this.$emit('close')
			}
		}
	}
}
</script>
