import { computed, ref } from '@vue/composition-api'

const NO_FOOTER_TEXT_FILTER = ''
const NO_SELECTED_PRODUCT = null

const footerTextSearchQueryState = ref(NO_FOOTER_TEXT_FILTER)
const selectedFooterProductState = ref(NO_SELECTED_PRODUCT)

export const changeFooterTextSearchQuery = searchQuery => {
	footerTextSearchQueryState.value = searchQuery
}
export const changeFooterProduct = selectedProduct => {
	selectedFooterProductState.value = selectedProduct
}

export const footerTextSearchQuery = computed({
	get () {
		return footerTextSearchQueryState.value
		// your getter
	},
	set (value) {
		changeFooterTextSearchQuery(value)
		// your setter
	}
})

export const selectedFooterProduct = computed({
	get () {
		return selectedFooterProductState.value
		// your getter
	},
	set (value) {
		changeFooterProduct(value)
		// your setter
	}
})

export const resetFooterFilters = () => {
	changeFooterTextSearchQuery(NO_FOOTER_TEXT_FILTER)
	changeFooterProduct(NO_SELECTED_PRODUCT)
}
