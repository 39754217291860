import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
/* eslint-disable camelcase */
import { required, email, image, ext, size, min, max, confirmed, numeric, alpha, alpha_num, regex, between, max_value, excluded } from 'vee-validate/dist/rules'

import Vue from 'vue'

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('email', {
	...email,
	message: 'Please enter a valid email'
})
extend('requiredEmails', {
	...required,
	message: 'At least one email address is required'
})
extend('required', {
	...required,
	message: 'This field is required'
})
extend('requiredBrands', {
	...required,
	message: 'At least one brand is required'
})

extend('requiredCookieScripts', {
	...required,
	message: 'At least one cookie script is required'
})

extend('requiredCookieCategories', {
	...required,
	message: 'At least one cookie category is required'
})
extend('requiredStrictlyNecessaryCategory', {
	...required,
	message: 'Strictly Necessary category is required'
})
extend('requiredDomains', {
	...required,
	message: 'At least one domain is required'
})

extend('image', {
	...image,
	message: 'Please upload a valid image'
})

extend('numeric', {
	...numeric,
	message: 'This field must be a positive number'
})

extend('alpha', {
	...alpha,
	message: 'This field must only contain letters'
})
/* eslint-disable camelcase */
extend('alpha_num', {
	...alpha_num,
	message: 'This field must only contain letters and numbers'
})

extend('regex', {
	...regex,
	message: 'Invalid characters - please check your input'
})

extend('size', {
	...size,
	message: (_, { size }) => {
		return `Image size must be less than ${size} `
	}
})
extend('min', {
	...min,
	message: (_, { length }) => {
		return `At least ${length} characters required`
	}
})
extend('max', {
	...max,
	message: (_, { length }) => {
		return `This field must contain less than ${length} characters`
	}
})
extend('max_value', {
	...max_value,
	message: (_, { max }) => {
		return `Value must not exceed ${max}`
	}
})
extend('betweenMinutes', {
	...between,
	message: (_, { min, max }) => {
		return `Value must be between ${min} and ${max} minutes`
	}
})
extend('confirmed', {
	...confirmed,
	message: (_, { target }) => {
		return `This field must match ${target}`
	}
})

extend('arrayIsNotEmpty', {
	params: ['value', 'ignoreVModel'],
	validate: (inputValue, { value: customValue, ignoreVModel }) => {
		const check = value => !!value && Array.isArray(value) && !!value.length
		return ignoreVModel ? check(customValue) : check(inputValue)
	},
	message: 'You must select at least one'
})

extend('ipRange', {
	// eslint-disable-next-line vue/max-len
	validate: value => value.match(/^(((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?))((\s?-\s?(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?))|(\/\d?\d))?)$/),
	message: 'This field must be a single ip address, or a range such as 65.34.77.0 - 65.34.77.9 or 65.34.77.0/24'
})

extend('isLink', {
	validate: value => value.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/),
	message: 'This field must be a valid domain'
})
extend('isUrl', {
	validate: value => value.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/),
	message: 'This field must be a valid URL'
})
extend('isUrlwithHttp', {
	validate: value => value.match(/^(http:\/\/|https:\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/),
	message: 'This field must must be a valid URL and start with http:// or https://'
})
extend('phoneNumber', {
	// eslint-disable-next-line no-useless-escape
	validate: value => value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/),
	message: 'This field must must be a valid mobile or telephone number'
})

extend('isValidScript', {
	...ext,
	message: 'Please upload a valid script file'
})

extend('isValidCss', {
	...ext,
	message: 'Please upload a valid CSS file'
})

extend('isValidResource', {
	...ext,
	message: 'Please upload a valid resource file type (.png, .jpg, .jpeg, .gif, .svg, .ttf)'
})

extend('forbiddenChars', {
	params: ['value'],
	validate: (inputValue, { value: forbiddenChars }) => {
		return inputValue.split('').every(char => !forbiddenChars.includes(char))
	},
	message: (_, { value }) => {
		return `These characters are forbidden: ${value.join(', ')}`
	}
})

extend('isDirectAuthWithPii', {
	params: ['value'],
	validate (value, { value: isDirectAuthWithPii }) {
		return value > 0 && !(value === 1 && isDirectAuthWithPii)
	},
	message: 'Direct authentication cannot be used if any PII is used on the Preference Page. Please select a different Authentication Type or remove PII from the form in the Build step.'
})

extend('forbiddenConsentType', {
	validate (value, { forbiddenTypes }) {
		return !forbiddenTypes.includes(value)
	},
	params: ['forbiddenTypes', 'categoryName'],
	message: (_, { categoryName }) => {
		return `The selected consent type has already been associated to ${categoryName}. GTM Consent types cannot be shared across categories.`
	}
})
extend('requiredPreferenceLinkStatement', {
	validate (value) {
		return value?.length === 0
	},
	message: 'A Preference Link requires at least one Statement'
})

extend('classOrId', {
	validate (value) {
		return Array.from(value)[0] === '.' || Array.from(value)[0] === '#'
	},
	message: 'This field must be a valid class or id'
})

extend('decimal', {
	validate: value => value.match(/^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/),
	message: 'This field must be a decimal number'
})

extend('password', {
	validate: value => value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-z\d!#$%&'()*+,-./:;<=>?@^_`{|}~]{12,}$/),
	message: 'Password must meet the minimum strength requirement'
})

extend('greaterThanZero', {
	...required,
	validate (value) {
		return value > 0
	},
	message: 'This field must be greater than zero'
})

extend('inUseLinkFieldNames', {
	...excluded,
	message: 'This Name in Link is already in use'
})

extend('existingStyleNames', {
	...excluded,
	message: 'This Style Name is already in use'
})
