import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	footers: []
}

export const getters = {
	footers (state) {
		return state.footers
	}
}

export const mutations = {
	SET_FOOTERS (state, footers) {
		const footersOrdered = footers
		footersOrdered.sort((a, b) => a.footerName.localeCompare(b.footerName))
		state.footers = footersOrdered
	},
	SET_FOOTER (state, footer) {
		const index = state.footers.findIndex(f => f.id === footer.id)
		if (index !== -1) {
			state.footers.splice(index, 1, footer)
		} else {
			state.footers.push(footer)
		}
	}
}

export const actions = {
	getFooter ({ commit }, id) {
		return configApi.get('/api/footers/' + id).then(response => {
			const footer = response.data
			commit('SET_FOOTER', footer)
			return footer
		})
	},
	getFooters ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/footers')
				.then(response => {
					const footers = response.data.footers
					commit('SET_FOOTERS', footers)
					resolve(footers)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
