import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../../utils/date-formatting.js'

export const getPrivacyPolicies = () => {
	return configApi.get(`/api/PrivacyPolicies`)
}

export const getActivePrivacyPolicies = () => {
	return configApi.get(`/api/PrivacyPolicies`).then(({ data }) => ({
		data: data.map(privacyPolicy => ({
			brandId: privacyPolicy.brandId,
			brandName: privacyPolicy.brandName,
			isGlobalDefault: privacyPolicy.isGlobalDefault,
			isVisibleInCSPortal: privacyPolicy.isVisibleInCSPortal,
			privacyPolicyId: privacyPolicy.privacyPolicyId,
			value: privacyPolicy.privacyPolicyId,
			text: privacyPolicy.versions.find(({ isActive }) => isActive).policyName,
			privacyPolicyName: privacyPolicy.versions.find(({ isActive }) => isActive).policyName,
			privacyPolicyUrl: privacyPolicy.versions.find(({ isActive }) => isActive).policyUrl ?? null,
			externalVersionRef: privacyPolicy.versions.find(({ isActive }) => isActive).externalVersionRef,
			activeVersionId: privacyPolicy.versions.find(({ isActive }) => isActive).versionId,
			createdDate: format(new Date(privacyPolicy.versions.find(({ isActive }) => isActive).createdDate), DATE_FORMAT)
		}))
	}))
}

export const deletePrivacyPolicy = privacyPolicyId => {
	return configApi.delete(`/api/PrivacyPolicies?PrivacyPolicyId=${privacyPolicyId}`)
}

export const postPrivacyPolicy = privacyPolicy => {
	return configApi.post(`/api/PrivacyPolicies`, privacyPolicy)
}

export const putPrivacyPolicy = privacyPolicy => {
	return configApi.put(`/api/PrivacyPolicies`, privacyPolicy)
}

export const setGlobalDefaultPrivacyPolicy = privacyPolicyId => {
	return configApi.put(`/api/PrivacyPolicies/SetGlobalDefault`, privacyPolicyId)
}
