import { computed, ref } from '@vue/composition-api'

const NO_META_DATA_KEY_SELECTED = null
const NO_META_DATA_VALUE_FILTER = ''

const metaDataKeyState = ref(NO_META_DATA_KEY_SELECTED)
const metaDataValueSearchQueryState = ref(NO_META_DATA_VALUE_FILTER)

export const changeMetaDataValueSearchQuery = searchQuery => {
	metaDataValueSearchQueryState.value = searchQuery
}

export const changeMetaDataKey = value => {
	metaDataKeyState.value = value
}

export const resetMetaDataFilters = () => {
	metaDataKeyState.value = NO_META_DATA_KEY_SELECTED
	metaDataValueSearchQueryState.value = NO_META_DATA_VALUE_FILTER
}

export const metaDataValueSearchQuery = computed({
	get () {
		return metaDataValueSearchQueryState.value
		// your getter
	},
	set (value) {
		changeMetaDataValueSearchQuery(value)
		// your setter
	}
})

export const selectedMetaDataKey = computed({
	get () {
		return metaDataKeyState.value
		// your getter
	},
	set (value) {
		changeMetaDataKey(value)
		// your setter
	}
})

export const COMPONENT_TYPE = {
	BRANDS: 1,
	CHANNELS: 2,
	COOKIE_SCRIPTS: 3,
	FOOTERS: 4,
	HEADERS: 5,
	LANGUAGES: 6,
	STATEMENTS: 7,
	PRIVACY_POLICIES: 8,
	PREFERENCE_WIDGETS: 9,
	CONSENT_COLLECTION_WIDGETS: 10
}
