import { computed, ref } from '@vue/composition-api'

const NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER = ''
const NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED = null

const consentCollectionWidgetSearchQueryState = ref(NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER)
const consentCollectionWidgetTranslationStatusIdState = ref(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED)

export const changeConsentCollectionWidgetSearchQuery = searchQuery => {
	consentCollectionWidgetSearchQueryState.value = searchQuery
}

export const changeConsentCollectionWidgetTranslationStatusId = value => {
	consentCollectionWidgetTranslationStatusIdState.value = value
}

export const consentCollectionWidgetSearchQuery = computed({
	get () {
		return consentCollectionWidgetSearchQueryState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionWidgetSearchQuery(value)
		// your setter
	}
})

export const consentCollectionWidgetTranslationStatusId = computed({
	get () {
		return consentCollectionWidgetTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionWidgetTranslationStatusId(value)
		// your setter
	}
})

export const resetConsentCollectionWidgetFilters = () => {
	changeConsentCollectionWidgetSearchQuery(NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER)
	changeConsentCollectionWidgetTranslationStatusId(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED)
}
