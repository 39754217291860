import { computed, ref } from '@vue/composition-api'
import { format } from 'date-fns'
import { getAdminPortalUsers } from '../utils/api/admin-portal-users.js'
import { DATE_FORMAT } from '../utils/date-formatting.js'
import { loadData } from '../utils/load-data.js'

const {
	data,
	load
} = loadData(getAdminPortalUsers)

const lookupData = computed(() => data.value.lookupData || {})

export const adminPortalUsers = computed(() => data.value.adminPortalUsers?.map(user => ({
	...user,
	name: user.forename + ' ' + user.surname,
	createdDate: format(new Date(user.createdDate), DATE_FORMAT),
	brandIds: user.brands.map(brand => {
		return brand.brandId
	}),
	brandNames: user.brands.map(brand => {
		return brand.brandName
	}).join(', '),
	legacyPermissionGroupIds: user.legacyPermissionsData.map(permissionGroup => {
		return permissionGroup.permissionGroupId
	})
})) || [])

export const adminPortalSsoEnabled = computed(() => data.value.ssoEnabled || false)
export const adminPortalSsoBypassDomains = computed(() => data.value.ssoBypassDomains || [])
export const adminPortalProductRoles = computed(() => lookupData.value.adminPortalProductRoles || [])
export const adminPortalPermissions = computed(() => lookupData.value.adminPortalPermissions || [])
export const adminPortalUserType = computed(() => 1)
export const legacyPermissionGroups = computed(() => lookupData.value.legacyPermissionGroups || [])
export const loadAdminPortalUsers = load

//  filters
const NO_ADMIN_PORTAL_USER_FILTER = ''
const NO_ADMIN_PORTAL_USER_STATUS_FILTER = null

const adminPortalUserSearchQueryState = ref(NO_ADMIN_PORTAL_USER_FILTER)
const adminPortalUserStatusFilterState = ref(NO_ADMIN_PORTAL_USER_STATUS_FILTER)

export const changeAdminPortalUserSearchQuery = searchQuery => {
	adminPortalUserSearchQueryState.value = searchQuery
}

export const changeAdminPortalUserStatusFilter = statusFilter => {
	adminPortalUserStatusFilterState.value = statusFilter
}

export const adminPortalUserSearchQuery = computed({
	get () {
		return adminPortalUserSearchQueryState.value
	},
	set (value) {
		changeAdminPortalUserSearchQuery(value)
	}
})

export const selectedAdminPortalUserStatus = computed({
	get () {
		return adminPortalUserStatusFilterState.value
	},
	set (value) {
		changeAdminPortalUserStatusFilter(value)
	}
})

export const resetAdminPortalUserFilters = () => {
	changeAdminPortalUserSearchQuery(NO_ADMIN_PORTAL_USER_FILTER)
	changeAdminPortalUserStatusFilter(NO_ADMIN_PORTAL_USER_STATUS_FILTER)
}
