import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_TEXT_TEMPLATE_NAME_FILTER = ''
const NO_COOKIE_TEXT_TEMPLATE_TRANSLATION_STATUS_FILTER = null

const cookieTextTemplateSearchQueryState = ref(NO_COOKIE_TEXT_TEMPLATE_NAME_FILTER)
const cookieTextTemplateTranslationStatusState = ref(NO_COOKIE_TEXT_TEMPLATE_TRANSLATION_STATUS_FILTER)

export const changeCookieTextTemplateSearchQuery = searchQuery => {
	cookieTextTemplateSearchQueryState.value = searchQuery
}

export const changeCookieTextTemplateTranslationStatus = translationStatus => {
	cookieTextTemplateTranslationStatusState.value = translationStatus
}

export const cookieTextTemplateSearchQuery = computed({
	get () {
		return cookieTextTemplateSearchQueryState.value
	},
	set (value) {
		changeCookieTextTemplateSearchQuery(value)
	}
})

export const cookieTextTemplateTranslationStatus = computed({
	get () {
		return cookieTextTemplateTranslationStatusState.value
	},
	set (value) {
		changeCookieTextTemplateTranslationStatus(value)
	}
})

export const resetCookieTextTemplateFilters = () => {
	changeCookieTextTemplateSearchQuery(NO_COOKIE_TEXT_TEMPLATE_NAME_FILTER)
	changeCookieTextTemplateTranslationStatus(NO_COOKIE_TEXT_TEMPLATE_TRANSLATION_STATUS_FILTER)
}
