import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import store from '../state/store.js'
import routes from './routes.js'
import { SSO_ERROR } from './route-names.js'
import { waitForNavBarItemsLoad, navBarItems } from '../../../shared/state/admin-portal-navigation.js'
import { waitForLoad } from '../../../shared/state/config-keys.js'
import { getLandingPageInfo } from '../../../shared/utils/api/landing-page.js'
import { getEnv } from '../../../shared/utils/runtime-settings.js'
import { uuid } from '../../../shared/utils/uuid.js'

// Silencing push/replace errors (NavigationDuplicated)
// Required after upgrading to v3.1
// https://github.com/vuejs/vue-router/issues/2881
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
Vue.use(VueMeta, {
	// The component option name that vue-meta looks for meta info on.
	keyName: 'page'
})

const router = new VueRouter({
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: 'history',
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	base: process.env.BASE_URL
})
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
	// If this isn't an initial page load...
	if (routeFrom.name !== null) {
		// Start the route progress bar.
		NProgress.start()
	}
	// Check for cookie indicating existing sesion
	await store.dispatch('auth/checkForExistingSession')
	// Check query string for JWT	token
	const urlParams = new URLSearchParams(window.location.search)
	if (urlParams.has('migjwt') && document.referrer !== null && document.referrer !== '') {
		const jwtString = urlParams.get('migjwt')
		sessionStorage.setItem('migjwt', 'true')
		await store.dispatch('auth/setJwtTokenFromString', jwtString)
		await store.dispatch('auth/getLegacyUrl', jwtString)
	}

	let userIsValid = false
	// Check if user is logged in but we haven't got their permission details
	if (store.getters['auth/loggedIn'] && !store.getters['auth/userPermissionsRetrieved']) {
		await store.dispatch('auth/validate').then(validUser => {
			userIsValid = (!!validUser)
			if (!userIsValid) next({ path: '/login' })
		})
		if (userIsValid) {
			await store.dispatch('auth/getUserDetails').catch(error => {
				console.warn(error)
				next({ path: '/login' })
			})
		}
	}
	if (store.getters['auth/loggedIn']) {
		await waitForNavBarItemsLoad()
		await waitForLoad()
	}
	// Redirect if navigating to the login page while already logged in (ADO#9680)
	let redirectPath = null
	if (routeTo != null && routeTo.name === 'login' && store.getters['auth/loggedIn'] && userIsValid) {
		// If there is no 'routeFrom' available, pick the first valid navbar route to redirect to
		let firstAvailableNavItem = null
		if (routeFrom?.name == null) {
			firstAvailableNavItem = navBarItems.value.find(topLevelNavItem => !topLevelNavItem.isExternal && topLevelNavItem.route.name != null && topLevelNavItem.sublinks.length < 1)
			if (firstAvailableNavItem == null) {
				for (let i = 0; i < navBarItems.value.length; i++) {
					if (firstAvailableNavItem != null) { break }
					firstAvailableNavItem = navBarItems.value[i].sublinks.find(sublink => !sublink.isExternal && sublink.route.name != null && sublink.deeperSublinks.length < 1)
					if (firstAvailableNavItem == null) {
						for (let j = 0; j < navBarItems.value[i].sublinks.length; j++) {
							if (firstAvailableNavItem != null) { break }
							firstAvailableNavItem = navBarItems.value[i].sublinks[j].deeperSublinks.find(deeperSublink => !deeperSublink.isExternal && deeperSublink.route != null && deeperSublink.route !== '')
						}
					}
				}
			}
		}

		redirectPath = routeFrom.path ?? firstAvailableNavItem.route?.name

		if (redirectPath == null) {
			// If all else fails
			redirectPath = '/login'
		}
		router.push({ path: redirectPath })
	}

	const isLogin = routeTo.name === 'login'
	const authRequired = routeTo.meta?.authRequired
	const permission = routeTo.meta?.permissionRequired

	let isSSOLoginType = false
	let identityClaimTypeName = null
	let tenantGuid = null
	if (isLogin) {
		const response = await getLandingPageInfo(1)
		const loginConfig = response.data?.loginConfig
		isSSOLoginType = loginConfig?.loginType === 'SSO' && !urlParams.has('noredirect')
		identityClaimTypeName = loginConfig?.identityClaimTypeName
		tenantGuid = loginConfig?.tenantGuid
	}
	const userLoggedIn = store.getters['auth/loggedIn']
	if (userLoggedIn) { // user already logged in so just continue
		next()
	}
	if (authRequired || isSSOLoginType) {
		if (isSSOLoginType) {
			// SSO Login
			const authHandler = getEnv('VUE_APP_BASE_URL_AUTH')

			sessionStorage.setItem('tenantGuid', tenantGuid)
			sessionStorage.setItem('authHandler', authHandler)
			sessionStorage.setItem('isSSO', 'true')

			const ssoState = sessionStorage.getItem('sso-state-admin')
			if (ssoState) { // existing SSO attempt in progress, so retrieve token
				const { identityClaimTypeName, stateGuid, productId } = JSON.parse(ssoState)
				sessionStorage.removeItem('sso-state-admin')
				try {
					// invoke /api/Authorize/SSO to get the JWT token
					await store.dispatch('auth/ssoLogIn', { identityClaimTypeName, stateGuid, productId })
					next({ path: '/dashboard' })
				} catch (_) {
					router.push({ name: SSO_ERROR })
				}
			} else { // initiate new SSO attempt
				const stateGuid = uuid()
				sessionStorage.setItem('sso-state-admin', JSON.stringify({ identityClaimTypeName, stateGuid, productId: 1 }))
				window.location.href = `${authHandler}/${tenantGuid}/${stateGuid}`
			}
			// if no migration URL & not logged in, redirect to login
		} else if (store.getters['auth/migrationUrl'] == null && !store.getters['auth/loggedIn']) {
			// if route is cookie-scanner-report or a cookie-scanner-download route, redirect to login with previousRoute param
			routeTo.name.includes('cookie-scanner-report') || routeTo.name.includes('cookie-scanner-download') ? router.push({ name: 'login', params: { previousRoute: routeTo } }) : next({ path: '/login' })
		} else if (permission !== undefined && !store.getters['auth/productAreaPermission'](permission)) {
			// If user tries to go to route they don't have permission for, redirect to the first route they have access to, or forbidden page if none available
			const redirectOr403 = redirectPath !== '/login' && redirectPath != null ? redirectPath : '/403'
			next({ path: redirectOr403 })
		} else {
			await store.dispatch('auth/validate').then(validUser => {
				// Then continue if the token still represents a valid user,
				// otherwise redirect to login.
				validUser ? next() : next({ path: '/login' })
			})
		}
	} else if (routeTo.path.search('http') >= 0) {
		window.open(routeTo.path, '_blank', 'noreferrer')
	} else {
		next()
	}
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
	// Complete the animation of the route progress bar.
	NProgress.done()
})
export default router
