import axios from 'axios'
import { configApi, refresherApi, transactionalApi } from './http-client.js'

let runtimeSettings = null

export const getEnv = name => {
	if (!runtimeSettings || !runtimeSettings[name]) throw new Error(`Failed to find ${name} variable`)
	return runtimeSettings[name]
}

export const loadRuntimeSettings = async () => {
	try {
		const { data } = await axios.get(location.origin + '/config.json')
		runtimeSettings = data
	} catch {} finally {
		configApi.instance.defaults.baseURL = getEnv('VUE_APP_BASE_URL')
		refresherApi.defaults.baseURL = getEnv('VUE_APP_BASE_URL')
		transactionalApi.instance.defaults.baseURL = getEnv('VUE_APP_BASE_URL_TRANSACTIONAL')
	}
}
