import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
	linksLookupData: null,
	progressiveProfilingLookupData: null,
	preferenceWidgetsLookupData: null
}

const getters = {
	linksLookupData: state => state.linksLookupData,
	progressiveProfileLookupData: state => state.progressiveProfilingLookupData,
	preferenceWidgetsLookupData: state => state.preferenceWidgetsLookupData
}

const mutations = {
	SET_LINKS_LOOKUP_DATA: (state, links) => {
		state.linksLookupData = links
	},
	SET_PROGRESSIVE_PROFILING_LOOKUP_DATA: (state, profile) => {
		state.progressiveProfilingLookupData = profile
	},
	SET_PREFERENCE_WIDGET_LOOKUP_DATA: (state, widgets) => {
		state.preferenceWidgetsLookupData = widgets
	}
}

const actions = {
	storeLinksLookupData: ({ commit }, linksLookupData) => {
		commit('SET_LINKS_LOOKUP_DATA', linksLookupData)
	},
	storeProgressiveProfilingLookupData: ({ commit }, ProgressiveProfileLookupData) => {
		commit('SET_PROGRESSIVE_PROFILING_LOOKUP_DATA', ProgressiveProfileLookupData)
	},
	storePreferenceWidgetLookupData: ({ commit }, preferenceWidgetsLookupData) => {
		commit('SET_PREFERENCE_WIDGET_LOOKUP_DATA', preferenceWidgetsLookupData)
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}
