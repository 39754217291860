import { computed, ref } from '@vue/composition-api'
import { getPrivacyPolicies } from '../utils/api/privacy-policies.js'

const privacyPoliciesState = ref(null)
let isLoading = false

const load = async () => {
	if (isLoading) return
	isLoading = true
	const { data: privacyPolicies } = await getPrivacyPolicies()
	privacyPoliciesState.value = privacyPolicies
	isLoading = false
}

export const updatePrivacyPolicies = () => {
	load()
}

export const privacyPolicies = computed(() => {
	if (privacyPoliciesState.value === null) {
		load()
		return []
	}
	return privacyPoliciesState.value
})

export const activePrivacyPolicies = computed(() => {
	if (privacyPoliciesState.value === null) {
		load()
		return []
	}
	const activePrivacyPolicies = privacyPoliciesState.value.filter(({ versions }) => versions.length > 0)
		.map(privacyPolicy => ({
			brandId: privacyPolicy.brandId,
			brandName: privacyPolicy.brandName,
			isGlobalDefault: privacyPolicy.isGlobalDefault,
			isVisibleInCSPortal: privacyPolicy.isVisibleInCSPortal,
			privacyPolicyId: privacyPolicy.privacyPolicyId,
			value: privacyPolicy.privacyPolicyId,
			text: privacyPolicy.versions.find(({ isActive }) => isActive)?.policyName,
			policyUrl: privacyPolicy.versions.find(({ isActive }) => isActive)?.policyUrl,
			activeVersionName: privacyPolicy.versions.find(({ isActive }) => isActive)?.externalVersionRef,
			activeVersionId: privacyPolicy.versions.find(({ isActive }) => isActive)?.versionId,
			createdDate: privacyPolicy.versions.find(({ isActive }) => isActive)?.createdDate
		}))
	const defaultPrivacyPolicy = activePrivacyPolicies.find(({ isGlobalDefault }) => isGlobalDefault)
	const nonDefaultPrivacyPolicies = activePrivacyPolicies.filter(({ isGlobalDefault }) => !isGlobalDefault)?.sort((a, b) => a?.text?.localeCompare(b?.text))
	if (defaultPrivacyPolicy == null) {
		return nonDefaultPrivacyPolicies
	}
	return [defaultPrivacyPolicy, ...nonDefaultPrivacyPolicies]
})

export const csPortalPrivacyPolicies = selectedBrand => {
	if (privacyPoliciesState.value === null) {
		load()
		return []
	}
	return activePrivacyPolicies.value.filter(({ isVisibleInCSPortal, brandId }) => isVisibleInCSPortal && (brandId === selectedBrand || brandId === 0))
}

// filters
const NO_PRIVACY_POLICY_TEXT_FILTER = ''

const privacyPolicySearchQueryState = ref(NO_PRIVACY_POLICY_TEXT_FILTER)

export const changePrivacyPolicySearchQuery = searchQuery => {
	privacyPolicySearchQueryState.value = searchQuery
}

export const privacyPolicySearchQuery = computed({
	get () {
		return privacyPolicySearchQueryState.value
		// your getter
	},
	set (value) {
		changePrivacyPolicySearchQuery(value)
		// your setter
	}
})

export const resetPrivacyPolicyFilters = () => {
	changePrivacyPolicySearchQuery(NO_PRIVACY_POLICY_TEXT_FILTER)
}
