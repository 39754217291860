<template>
	<Modal
		v-if="proceedWithUnsavedChangesAction"
		width="500px"
	>
		<template #modal-title>
			Are you sure?
		</template>
		<template #modal-content>
			You have made changes that have not been saved. Are you sure you want to proceed?
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="clearProceedWithUnsavedChangesAction">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton @click="proceed">
				Proceed
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import NProgress from 'nprogress'
import SecondaryActionButton from './secondary-action-button.vue'
import PrimaryActionButton from './primary-action-button.vue'
import { validationObservers } from '../state/validation-observers.js'
import Modal from './modal.vue'

export default {
	name: 'unsaved-changes-modal',
	components: { Modal, PrimaryActionButton, SecondaryActionButton },
	setup () {
		const proceedWithUnsavedChangesAction = ref(null)
		const hasChangedFields = computed(() => validationObservers.value.some(({ flags }) => flags.changed))
		const clearProceedWithUnsavedChangesAction = () => (proceedWithUnsavedChangesAction.value = null)
		const proceed = () => {
			proceedWithUnsavedChangesAction.value()
			clearProceedWithUnsavedChangesAction()
		}
		return {
			clearProceedWithUnsavedChangesAction,
			proceed,
			proceedWithUnsavedChangesAction,
			hasChangedFields
		}
	},
	created () {
		this.$router.beforeEach(async (_, __, next) => {
			setTimeout(() => {
				if (this.hasChangedFields) {
					this.proceedWithUnsavedChangesAction = true
					this.proceedWithUnsavedChangesAction = next
					NProgress.done()
					return
				}
				next()
			}, 50)
		})
	}
}
</script>
