import { configApi, refresherApi } from '../http-client.js'

export const SSO_TOKEN_API = '/api/Authorize/SSO'

export const loginApi = ({ username, password }) => {
	return configApi.post('/api/Authorize/CSPortalUser', { username, password })
}
export const refreshTokenApi = () => {
	return refresherApi.get('/api/Authorize/CSPortalUserRefreshToken')
}
export const ssoLoginApi = ({ identityClaimTypeName, stateGuid, productId }) => {
	return configApi.post(SSO_TOKEN_API, { identityClaimName: identityClaimTypeName, stateGuid, productId })
}
export const currentUserDetailsApi = () => {
	return configApi.get('/CSPortal/api/Users/GetCurrentUserDetails')
}
