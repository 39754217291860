<template>
	<v-dialog
		:value="showModal"
		:persistent="persistent"
		v-bind="$props"
		content-class="d-flex"
	>
		<v-card class="d-flex flex-column">
			<div class="d-flex flex-row align-center">
				<v-card-title class="flex cassie-word-break">
					<slot name="modal-title" />
				</v-card-title>
				<v-spacer v-if="$slots['modal-title-action']" />
				<div
					v-if="$slots['modal-title-action']"
					class="mr-4"
				>
					<slot name="modal-title-action" />
				</div>
			</div>
			<v-divider />
			<v-card-text
				class="py-6 overflow-auto"
			>
				<slot name="modal-content" />
			</v-card-text>
			<v-divider />
			<v-card-actions class="justify-end py-4 px-6 cassie-horizontal-sm">
				<slot name="modal-footer" />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { VDialog } from 'vuetify/lib'

export default {
	name: 'modal',
	extends: VDialog,
	props: {
		showModal: {
			type: Boolean,
			default: true
		},
		persistent: {
			type: Boolean,
			default: true
		},
		fullHeight: {
			type: Boolean,
			default: false
		}
	}
}
</script>
