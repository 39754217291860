import { computed, ref } from '@vue/composition-api'

export const loadData = apiGet => {
	const data = ref(null)

	let isLoading = false
	let isLoadingPromise = null

	const load = async () => {
		if (isLoading) return
		isLoading = true
		isLoadingPromise = apiGet()
		const { data: response } = await isLoadingPromise
		data.value = response
		isLoading = false
	}

	const waitFor = async () => {
		if (isLoadingPromise === null) load()
		return isLoadingPromise
	}

	return {
		data: computed(() => {
			if (data.value === null) {
				load()
				return {}
			}
			return data.value
		}),
		load,
		waitFor
	}
}
