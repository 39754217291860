import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	brands: []
}

export const getters = {
	brands (state) {
		return state.brands
	},
	brandFilter (state) {
		const brandFilter = [
			{
				brandName: 'View All',
				brandId: -1
			}
		]
		brandFilter.push(...state.brands)

		const universal = brandFilter.findIndex(brand => brand.brandId === 0)
		brandFilter.splice(universal, 1)
		return brandFilter
	}
}

export const mutations = {
	SET_BRANDS (state, brands) {
		const brandsOrdered = brands
		brandsOrdered.sort((a, b) => a.brandName.localeCompare(b.brandName))
		state.brands = brandsOrdered
	}
}

export const actions = {
	getBrands ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/brands')
				.then(response => {
					const brands = response.data
					commit('SET_BRANDS', brands)
					resolve(brands)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}
