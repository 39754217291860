import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_BANNER_TEXT_FILTER = ''

const cookieBannerSearchQueryState = ref(NO_COOKIE_BANNER_TEXT_FILTER)

export const changeCookieBannerSearchQuery = searchQuery => {
	cookieBannerSearchQueryState.value = searchQuery
}

export const cookieBannerSearchQuery = computed({
	get () {
		return cookieBannerSearchQueryState.value
		// your getter
	},
	set (value) {
		changeCookieBannerSearchQuery(value)
		// your setter
	}
})

export const resetCookieBannerFilters = () => {
	changeCookieBannerSearchQuery(NO_COOKIE_BANNER_TEXT_FILTER)
}
