import { computed, ref } from '@vue/composition-api'
import Vue from 'vue'
import { getAdminPortalNavbarItems } from '../utils/api/admin-portal-navigation.js'
const navBarItemsState = ref(null)
const labels = ref([])
let isLoading = false
let isLoadingPromise = null

const load = async () => {
	if (isLoading) return
	isLoading = true
	isLoadingPromise = getAdminPortalNavbarItems()
	const { adminPortalNavigation } = await isLoadingPromise
	navBarItemsState.value = adminPortalNavigation
	labels.value = [...adminPortalNavigation
		.filter(({ displayText }) => displayText != null)
		.map(({ displayText, text }) => ({ displayText, text })),
	...adminPortalNavigation
		.map(({ sublinks }) => sublinks
			.filter(({ displayText }) => displayText != null)
			.map(({ displayText, text }) => ({ displayText, text }))).flat(),
	...adminPortalNavigation
		.map(({ sublinks }) => sublinks
			.map(({ deeperSublinks }) => deeperSublinks
				.filter(({ displayText }) => displayText != null)
				.map(({ displayText, text }) => ({ displayText, text }))).flat()).flat()]
	isLoading = false
}

export const clearNavbarItems = () => {
	navBarItemsState.value = null
}

export const useLabels = value => {
	if (value == null) return ''
	// convert to string
	let result = '' + value
	labels.value.forEach(({ text, displayText }) => {
		// if text is plural remove s
		if (text.endsWith('s')) {
			text = text.slice(0, -1)
		}
		if (value.includes(text)) {
			result = result.replace(text, displayText, 'g')
		}
		// match and replace lowercase
		if (value.includes(text.toLowerCase())) {
			result = result.replace(text.toLowerCase(), displayText.toLowerCase(), 'g')
		}

		if (result.endsWith('ys') && !result.toLowerCase().includes('keys')) {
			result = result.replace('ys', 'ies', 'g')
		}
	})
	return result
}
Vue.filter('useLabels', useLabels)

export const waitForNavBarItemsLoad = async () => {
	if (isLoadingPromise === null || navBarItemsState.value == null) {
		load()
	}
	return isLoadingPromise
}

export const navBarItems = computed(() => {
	if (navBarItemsState.value === null) {
		load()
		return {}
	}
	return navBarItemsState.value
})

export const navBarItemsTest = computed(() => navBarItems.value || {})
