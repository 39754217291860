import { computed, ref } from '@vue/composition-api'
import { getConfigKeys } from '../utils/api/config-keys.js'

const configKeysState = ref(null)

let isLoading = false
let isLoadingPromise = null

const load = async () => {
	if (isLoading) return
	isLoading = true
	isLoadingPromise = getConfigKeys()
	const configKeysData = await isLoadingPromise
	configKeysState.value = configKeysData.data
	isLoading = false
}

export const waitForLoad = async () => {
	if (isLoadingPromise === null) load()
	return isLoadingPromise
}

export const configKeys = computed(() => {
	if (configKeysState.value === null) {
		load()
		return {}
	}
	return configKeysState.value
})

export const adminPortalBrandFieldLabel = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'AdminPortalBrandLabel')?.keyValue)
export const noOfAllowedExtendedPrefKeys = computed(() => Number(configKeys.value?.find(({ keyName }) => keyName === 'NoOfAllowedExtendedPrefKeys').keyValue))
export const licenseId = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'LicenseID').keyValue)
export const senderEmailsIdentityLimit = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'SenderEmailsIdentityLimit').keyValue)
export const zohoNewTicketLink = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ZohoNewTicketLink').keyValue)
export const zohoViewTicketLink = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ZohoViewTicketsLink').keyValue)
const statementDrafts = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'StatementDrafts').keyValue.toLowerCase())
const cassieIdentityService = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'IsCISVisibleInAdminPortal').keyValue)
const enableLegacyPermissionsInVue = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'EnableLegacyPermissionsInVuePortal').keyValue.toLowerCase())
export const activeExportsLimit = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ExportsEnabled')?.keyValue)
export const exportsLatestFileSizeLimit = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ExportsLatestFileSizeLimit')?.keyValue)
export const cookieBannerLegacyEmbedCodeVueDisplay = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'CookieBannerLegacyEmbedCodeVueDisplay')?.keyValue.toLowerCase() === 'true')
export const enableCassieCloudEmbedCode = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'EnableCassieCloudEmbedCode')?.keyValue.toLowerCase() === 'true')
export const enableTransactionTypes = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'EnableTransactionTypes')?.keyValue.toLowerCase() === 'true')
export const enableConsentCollectionPreferencePageOptions = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ConsentCollectionPreferencePageOptions')?.keyValue)
export const enableConsentCollectionWidgetOptions = computed(() => configKeys.value?.find(({ keyName }) => keyName === 'ConsentCollectionWidgetOptions')?.keyValue)

export const showStatementDrafts = computed(() => {
	return statementDrafts.value === 'true'
})

export const showLegacyPermissions = computed(() => {
	return enableLegacyPermissionsInVue.value === 'true'
})

export const siteScannerVersion = computed(() => Number(configKeys.value?.find(({ keyName }) => keyName === 'SiteScannerVersion').keyValue))

export const cassieIdentityServiceEnabled = computed(() => {
	return cassieIdentityService.value === '1'
})
