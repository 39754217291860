import { computed, ref } from '@vue/composition-api'

// preference Links
const NO_PREFERENCE_LINK_TEXT_FILTER = ''

const preferenceLinkSearchQueryState = ref(NO_PREFERENCE_LINK_TEXT_FILTER)

export const changePreferenceLinkSearchQuery = searchQuery => {
	preferenceLinkSearchQueryState.value = searchQuery
}

export const preferenceLinkSearchQuery = computed({
	get () {
		return preferenceLinkSearchQueryState.value
	},
	set (value) {
		changePreferenceLinkSearchQuery(value)
	}
})

// action links
const NO_ACTION_LINK_TEXT_FILTER = ''

const actionLinkSearchQueryState = ref(NO_ACTION_LINK_TEXT_FILTER)

export const changeActionLinkSearchQuery = searchQuery => {
	actionLinkSearchQueryState.value = searchQuery
}

export const actionLinkSearchQuery = computed({
	get () {
		return actionLinkSearchQueryState.value
	},
	set (value) {
		changeActionLinkSearchQuery(value)
	}
})

// public portal links
const NO_PUBLIC_PORTAL_LINK_TEXT_FILTER = ''

const publicPortalLinkSearchQueryState = ref(NO_PUBLIC_PORTAL_LINK_TEXT_FILTER)

export const changePublicPortalLinkSearchQuery = searchQuery => {
	publicPortalLinkSearchQueryState.value = searchQuery
}

export const publicPortalLinkSearchQuery = computed({
	get () {
		return publicPortalLinkSearchQueryState.value
	},
	set (value) {
		changePublicPortalLinkSearchQuery(value)
	}
})

// link translations
const NO_LINK_TRANSLATION_TEXT_FILTER = ''
const NO_LINK_TRANSLATIONS_STATUS_FILTER = null

const linkTranslationSearchQueryState = ref(NO_LINK_TRANSLATION_TEXT_FILTER)
const linkTranslationsStatusFilterState = ref(NO_LINK_TRANSLATIONS_STATUS_FILTER)

export const changeLinkTranslationSearchQuery = searchQuery => {
	linkTranslationSearchQueryState.value = searchQuery
}

export const changeLinkTranslationsStatusFilter = status => {
	linkTranslationsStatusFilterState.value = status
}

export const linkTranslationSearchQuery = computed({
	get () {
		return linkTranslationSearchQueryState.value
	},
	set (value) {
		changeLinkTranslationSearchQuery(value)
	}
})

export const linkTranslationsStatusFilter = computed({
	get () {
		return linkTranslationsStatusFilterState.value
	},
	set (value) {
		changeLinkTranslationsStatusFilter(value)
	}
})

// shared
const NO_LINK_AUTH_TYPE = null
const NO_LINKS_TRANSLATION_LANGUAGE_SELECTED = null
const NO_LINK_ENABLED_STATUS = null

const linkAuthTypeState = ref(NO_LINK_AUTH_TYPE)
const linksTranslationLanguageSelectedState = ref(NO_LINKS_TRANSLATION_LANGUAGE_SELECTED)
const linkEnabledStatusState = ref(NO_LINK_ENABLED_STATUS)

export const changeLinkAuthType = authType => {
	linkAuthTypeState.value = authType
}
export const linkAuthType = computed({
	get () {
		return linkAuthTypeState.value
	},
	set (value) {
		changeLinkAuthType(value)
	}
})

export const changeLinksTranslationLanguageSelected = language => {
	linksTranslationLanguageSelectedState.value = language
}

export const linksTranslationLanguageSelected = computed({
	get () {
		return linksTranslationLanguageSelectedState.value
	},
	set (value) {
		changeLinksTranslationLanguageSelected(value)
	}
})

export const changeLinkEnabledStatus = status => {
	linkEnabledStatusState.value = status
}

export const linkEnabledStatus = computed({
	get () {
		return linkEnabledStatusState.value
	},
	set (value) {
		changeLinkEnabledStatus(value)
	}
})

export const resetLinkFilters = () => {
	changePreferenceLinkSearchQuery(NO_PREFERENCE_LINK_TEXT_FILTER)
	changeActionLinkSearchQuery(NO_ACTION_LINK_TEXT_FILTER)
	changePublicPortalLinkSearchQuery(NO_PUBLIC_PORTAL_LINK_TEXT_FILTER)
	changeLinkTranslationSearchQuery(NO_LINK_TRANSLATION_TEXT_FILTER)
	changeLinkAuthType(NO_LINK_AUTH_TYPE)
	changeLinksTranslationLanguageSelected(NO_LINKS_TRANSLATION_LANGUAGE_SELECTED)
	changeLinkTranslationsStatusFilter(NO_LINK_TRANSLATIONS_STATUS_FILTER)
	changeLinkEnabledStatus(NO_LINK_ENABLED_STATUS)
}
