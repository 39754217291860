<template>
	<div id="app">
		<link
			rel="preconnect"
			href="https://fonts.gstatic.com"
		>
		<v-app>
			<ApplicationLayout
				v-if="showMainLayout && tokenInDate && !sessionExpired"
				:links="links"
				:show-spinner="loadingSpinner"
				:change-show-filters="changeShowFilters"
				:reset-admin-portal-filters="resetAdminPortalFilters"
			>
				<RouterView :key="$route.fullPath" />
			</ApplicationLayout>
			<RouterView
				v-else-if="!sessionExpired"
				:key="$route.fullPath"
			/>
			<v-snackbar
				:value="!!snackbar"
				top
				:color="snackbar ? snackbar.color : ''"
				@input="snackbar = null"
			>
				{{ snackbar ? snackbar.text : '' }}
				<template #action="{ attrs }">
					<v-btn
						color="white"
						text
						v-bind="attrs"
						@click="snackbar = null"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<UnsavedChangesModal />
			<SessionExpiryModal
				v-if="sessionWarning"
				title="Session Expiry Warning"
				:countdown="countdown"
				:keep-logged-in="renewSession"
				:log-out="logOut"
			/>
		</v-app>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appConfig from '../src/app.config.json'
import ApplicationLayout from '../../shared/layouts/application-layout.vue'
import { navBarItems } from '../../shared/state/admin-portal-navigation.js'
import { zohoNewTicketLink, zohoViewTicketLink } from '../../shared/state/config-keys.js'
import { snackbar } from '../../shared/state/snackbar.js'
import { loadingSpinner } from '../../shared/state/loading-spinner.js'
import { changeShowFilters } from '../../shared/state/filters.js'
import { resetAdminPortalFilters } from '../../shared/utils/filters.js'
import UnsavedChangesModal from '../../shared/components/unsaved-changes-modal.vue'
import SessionExpiryModal from '../../shared/components/session-expiry-modal.vue'

export default {
	components: { UnsavedChangesModal, ApplicationLayout, SessionExpiryModal },
	provide () {
		return {
			logOutAndRedirect: this.logOut,
			userFullName: () => this.userFullName
		}
	},
	setup () {
		return {
			loadingSpinner,
			snackbar,
			zohoNewTicketLink,
			zohoViewTicketLink,
			changeShowFilters,
			resetAdminPortalFilters
		}
	},
	data () {
		return {
			sessionExpired: false
		}
	},
	computed: {
		...mapGetters('auth', ['userFullName', 'migrationUrl', 'productAreaPermission', 'tokenInDate', 'sessionWarning', 'countdown']),
		showMainLayout () {
			return this.$route.meta?.authRequired
		},
		links () {
			if (navBarItems?.value == null) {
				return null
			}
			return navBarItems.value.map(topLevelNavItem => ({
				...topLevelNavItem,
				customAction: this.getParentLevelCustomAction(topLevelNavItem),
				sublinks: topLevelNavItem.sublinks
					? topLevelNavItem.sublinks.map(subLinkNavItem => ({
						...subLinkNavItem,
						customAction: this.getChildLevelCustomAction(subLinkNavItem),
						deeperSublinks: subLinkNavItem.deeperSublinks
							? subLinkNavItem.deeperSublinks.map(deeperSublinkItem => ({
								...deeperSublinkItem,
								customAction: this.getGrandchildLevelCustomAction(deeperSublinkItem)
							}))
							: null
					}))
					: null
			}))
		}
	},
	watch: {
		sessionWarning (val) {
			if (!val) {
				this.sessionExpired = false
			}
		}
	},
	page: {
		titleTemplate (title) {
			title = typeof title === 'function' ? title(this.$store) : title
			return title ? `${title} | ${appConfig.title}` : appConfig.title
		}
	},
	mounted () {
		const link = document.createElement('link')
		link.type = 'image/svg+xml'
		link.rel = 'shortcut icon'
		link.href = require('../src/assets/favicon.svg')
		document.getElementsByTagName('head')[0].appendChild(link)
	},
	methods: {
		...mapActions('auth', ['logOut', 'getLegacyUrl', 'validate']),
		async goToExternalUrl (route, text) {
			if (text === 'Create Support Ticket' && zohoNewTicketLink.value != null) {
				window.open(zohoNewTicketLink.value)
			} else if (text === 'View Support Ticket' && zohoViewTicketLink.value != null) {
				window.open(zohoViewTicketLink.value)
			} else {
				if (this.migrationUrl == null) {
					const migrationUrl = await this.getLegacyUrl()
					if (window.location.hostname === 'localhost' || (migrationUrl == null)) {
						return null
					}
				}
				if (this.migrationUrl != null) {
					location.href = `http://${this.migrationUrl[1]}/${route}`
				}
			}
		},
		getParentLevelCustomAction (parentLevelNavItem) {
			return parentLevelNavItem.isExternal ? () => this.goToExternalUrl(parentLevelNavItem.route, parentLevelNavItem.text) : null
		},
		getChildLevelCustomAction (childLevelNavItem) {
			return childLevelNavItem.isExternal ? () => this.goToExternalUrl(childLevelNavItem.route, childLevelNavItem.text) : null
		},
		getGrandchildLevelCustomAction (grandchildLevelNavItem) {
			return grandchildLevelNavItem.isExternal ? () => this.goToExternalUrl(grandchildLevelNavItem.route, grandchildLevelNavItem.text) : null
		},
		renewSession () {
			this.validate().then(() => {
				this.$store.commit('auth/SET_SESSION_WARNING', false)
			})
		}
	}
}
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
//
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

@import './styles';
// poppins font
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins-Italic';
  src: url('./fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
}

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Style variables and utilities from src/styles.
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html.prevent-scroll,
body.prevent-scroll {
  height: auto !important;
  padding-right: 17px;
  overflow-y: hidden !important;
}
html {
  overflow: hidden !important;
}

html,
body,
.v-application {
  height: 100%;
}

body {
  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background: none !important;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #cfd8dc;
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 99999px;
  }
}

.v-select-list {
  margin-top: 10px !important;
  ::-webkit-scrollbar-track {
    background: #fff !important;
  }
}
#app {
  @extend %typography-small;
  height: 100%;
}

// ===
// Base element styles
// ===

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}

// override vuetify default style globally

.v-application .text-h1.text-h1,
.v-application .text-h2.text-h2,
.v-application .text-h3.text-h3,
.v-application .text-h4.text-h4,
.v-application .text-h5.text-h5,
.v-application .text-h6.text-h6,
.v-application .text-subtitle-1.text-subtitle-1,
.v-application .text-subtitle-2.text-subtitle-2,
.v-application .text-body-1.text-body-1,
.v-application .text-body-2.text-body-2,
.v-application .text-button.text-button,
.v-application .text-caption.text-caption,
.v-application .text-overline.text-overline {
  font-family: 'Poppins', sans-serif !important;
}

.v-tooltip__content {
  max-width: 300px;
}

// Tab background none for consent banner page
.theme--light.v-tabs-items {
  width: 100%;
  background-color: rgba(0, 0, 0, 0) !important;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: var(--global-color-blue-darken-2);
}

.container {
  overflow-y: auto;
}
</style>
