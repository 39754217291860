import { computed, ref } from '@vue/composition-api'

const NO_COOKIE_CATEGORY_NAME_FILTER = ''
const NO_SELECTED_TYPE = null

const cookieCategorySearchQueryState = ref(NO_COOKIE_CATEGORY_NAME_FILTER)
const selectedCookieCategoryTypeState = ref(NO_SELECTED_TYPE)

export const changeCookieCategorySearchQuery = searchQuery => {
	cookieCategorySearchQueryState.value = searchQuery
}
export const changeCookieCategoryType = selectedType => {
	selectedCookieCategoryTypeState.value = selectedType
}

export const cookieCategorySearchQuery = computed({
	get () {
		return cookieCategorySearchQueryState.value
	},
	set (value) {
		changeCookieCategorySearchQuery(value)
	}
})

export const selectedCookieCategoryType = computed({
	get () {
		return selectedCookieCategoryTypeState.value
	},
	set (value) {
		changeCookieCategoryType(value)
	}
})

export const resetCookieCategoryFilters = () => {
	changeCookieCategorySearchQuery(NO_COOKIE_CATEGORY_NAME_FILTER)
	changeCookieCategoryType(NO_SELECTED_TYPE)
}
