import { computed, ref } from '@vue/composition-api'

const showFiltersState = ref(false)

export const changeShowFilters = value => {
	showFiltersState.value = value
}

export const selectShowFilters = computed({
	get () {
		return showFiltersState.value
		// your getter
	},
	set (value) {
		changeShowFilters(value)
		// your setter
	}
})
