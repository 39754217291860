import { computed, ref } from '@vue/composition-api'

const NO_PRODUCT_ROLE_TEXT_FILTER = ''

const productRoleTextSearchQueryState = ref(NO_PRODUCT_ROLE_TEXT_FILTER)

export const changeProductRoleTextSearchQuery = searchQuery => {
	productRoleTextSearchQueryState.value = searchQuery
}

export const productRoleTextSearchQuery = computed({
	get () {
		return productRoleTextSearchQueryState.value
	},
	set (value) {
		changeProductRoleTextSearchQuery(value)
	}
})

export const resetProductRoleFilters = () => {
	changeProductRoleTextSearchQuery(NO_PRODUCT_ROLE_TEXT_FILTER)
}
