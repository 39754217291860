import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import '../../shared/plugins/composition.js'
import router from './router/index.js'
import store from './state/store.js'
import vuetify from './plugins/vuetify.js'
import froala from './plugins/froala.js'
import './themes/prism-vsc-dark-plus.css'
import '../../shared/validation/index.js'
import { SSO_ERROR } from './router/route-names.js'

// Import and use Vue Froala lib.
import App from './app.vue'
import { loadRuntimeSettings } from '../../shared/utils/runtime-settings.js'
import { setHttpClientConfig } from '../../shared/utils/http-client.js'

import { SSO_TOKEN_API } from '../../shared/utils/api/login.js'

// Globally register all `_base`-prefixed components

Vue.use(froala)
Vue.use(VueDOMPurifyHTML)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV === 'development'

loadRuntimeSettings().then(() => {
	new Vue({
		vuetify,
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
	setHttpClientConfig({
		unauthorizedHandler: async unauthorizedApiUrl => {
			try {
				const response = await fetch(unauthorizedApiUrl)
				if (!response.ok) {
					// Check if it's a 401 Unauthorized error
					if (response.status === 401) {
						// Parse the response body to check for the specific error message
						const data = await response.json()
						if (data.error === 'Incorrect Email Address or Password') {
							store.dispatch('auth/logOut', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : null)
						} else {
							store.dispatch('auth/sessionExpired', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : null)
						}
					}
				}
			} catch (error) {
				console.error('Error handling unauthorized request:', error)
				store.dispatch('auth/sessionExpired', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : null)
			}
		},
		loginUrl: '/api/Authorize/adminportaluser',
		logoutUrl: '/api/Authorize/adminportaluserlogout',
		otpLoginUrl: '/api/Authorize/LoginOtp'
	})
})
