import { configApi } from '../../../../shared/utils/http-client.js'

export const state = {
	cookieCategories: [],
	selectedCategory: {}
}

export const getters = {
	cookieCategories (state) {
		return state.cookieCategories
	},
	cookieCategoriesFilter (state) {
		const cookieCategoriesFilter = [
			{
				categoryId: -1,
				categoryName: 'View All'
			}
		]
		cookieCategoriesFilter.push(...state.cookieCategories)

		return cookieCategoriesFilter
	},
	selectedCategory (state) {
		return state.selectedCategory
	}
}

export const mutations = {
	SET_COOKIECATEGORIES (state, cookieCategories) {
		const cookieCategoriesOrdered = cookieCategories
		cookieCategoriesOrdered.sort((a, b) =>
			a.categoryName.localeCompare(b.categoryName)
		)
		state.cookieCategories = cookieCategoriesOrdered
	},
	SET_COOKIECATEGORY (state, category) {},
	CREATE_CATEGORY (state, category) {
		state.cookieCategories.push(category)
		state.cookieCategories.sort((a, b) =>
			a.categoryName.localeCompare(b.categoryName)
		)
	},
	UPDATE_CATEGORY (state, category) {
		const matchingCategory = state.cookieCategories.find(
			stateCategory => stateCategory.categoryId === category.id
		)
		matchingCategory.categoryName = category.categoryName
		matchingCategory.displayText = category.displayText
		matchingCategory.brandName = category.brandName
		state.cookieCategories.sort((a, b) =>
			a.categoryName.localeCompare(b.categoryName)
		)
	},
	DELETE_COOKIECATEGORY (state, id) {
		const index = state.cookieCategories.findIndex(
			category => category.categoryId === id
		)

		// if index = -1 don't remove anything
		if (index !== -1) {
			state.cookieCategories.splice(index, 1)
		}
	},
	SET_SELECTEDCOOKIECATEGORY (state, id) {
		state.selectedCategory = state.cookieCategories.find(
			category => category.categoryId === id
		)
	}
}

export const actions = {
	getCookieCategory ({ commit }, id) {
		return configApi.get('/api/cookieCategory/' + id).then(response => {
			const category = response.data
			commit('SET_COOKIECATEGORY', category)
			return category
		})
	},
	getCookieCategories ({ commit }) {
		return new Promise((resolve, reject) => {
			configApi
				.get('/api/cookieCategory')
				.then(response => {
					const cookieCategories = response.data
					commit('SET_COOKIECATEGORIES', cookieCategories)
					resolve(cookieCategories)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	createCookieCategory ({ commit, rootState }, category) {
		return configApi
			.post('/api/cookieCategory', category)
			.then(response => {
				const newCategory = response.data
				const brandState = rootState.brands

				// find brand id that matches category.brandID
				const matchingBrand = brandState.brands.find(
					brand => brand.brandId === category.brandId
				)

				category.categoryId = newCategory.newCookieCategoryId
				category.brandName = matchingBrand.brandName
				category.countOfAssociatedBanners = 0
				category.countOfAssociatedCookieScripts = 0

				commit('CREATE_CATEGORY', category)

				return newCategory
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},
	updateCookieCategory ({ commit, rootState }, category) {
		return configApi.put('/api/cookieCategory', category).then(response => {
			const updatedCategory = response.data
			const brandState = rootState.brands

			const matchingBrand = brandState.brands.find(
				brand => brand.brandId === category.brandId
			)

			category.brandName = matchingBrand.brandName

			commit('UPDATE_CATEGORY', category)
			return updatedCategory
		})
	},
	deleteCookieCategory ({ commit }, id) {
		return configApi
			.delete('/api/CookieCategory/' + id)
			.then(response => {
				const deletedCookieCategory = response.data
				commit('DELETE_COOKIECATEGORY', id)
				return deletedCookieCategory
			})
			.catch(function (error) {
				console.error(error.response.data)
			})
	},

	setSelectedCookieCategory ({ commit }, id) {
		commit('SET_SELECTEDCOOKIECATEGORY', id)
	}
}
