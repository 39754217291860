import { computed, ref } from '@vue/composition-api'

const NO_SYSTEM_USER_TEXT_FILTER = ''
const NO_API_KEY_NAME = null

const systemUserTextSearchQueryState = ref(NO_SYSTEM_USER_TEXT_FILTER)
const apiKeyNameState = ref(NO_API_KEY_NAME)

export const changeSystemUserTextSearchQuery = searchQuery => {
	systemUserTextSearchQueryState.value = searchQuery
}

export const changeApiKeyNameFilter = value => {
	apiKeyNameState.value = value
}

export const systemUserTextSearchQuery = computed({
	get () {
		return systemUserTextSearchQueryState.value
	},
	set (value) {
		changeSystemUserTextSearchQuery(value)
	}
})

export const apiKeyNameFilter = computed({
	get () {
		return apiKeyNameState.value
	},
	set (value) {
		changeApiKeyNameFilter(value)
	}
})

export const resetSystemUserFilters = () => {
	changeSystemUserTextSearchQuery(NO_SYSTEM_USER_TEXT_FILTER)
	changeApiKeyNameFilter(NO_API_KEY_NAME)
}
