import { computed, ref } from '@vue/composition-api'
import { getPreferencePageTranslations } from '../utils/api/preference-pages.js'

const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER = ''
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW = {}

const consentCollectionPreferencePageSearchQueryState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER)
const consentCollectionPreferencePageTranslationStatusIdState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED)
const consentCollectionPreferencePageAuthTypeState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE)
const consentCollectionPreferencePageLanguageSelectedState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED)
const consentCollectionPreferencePageTranslationOverviewState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW)

export const changeConsentCollectionPreferencePageSearchQuery = searchQuery => {
	consentCollectionPreferencePageSearchQueryState.value = searchQuery
}

export const changeConsentCollectionPreferencePageTranslationStatusId = value => {
	consentCollectionPreferencePageTranslationStatusIdState.value = value
}

export const changeConsentCollectionPreferencePageAuthType = authType => {
	consentCollectionPreferencePageAuthTypeState.value = authType
}

export const changeConsentCollectionPreferencePageTranslationLanguageSelected = language => {
	if (consentCollectionPreferencePageLanguageSelectedState.value !== language) {
		consentCollectionPreferencePageLanguageSelectedState.value = language
		getPreferencePageTranslations(language)
			.then(({ data }) => { consentCollectionPreferencePageTranslationOverviewState.value = data })
	}
}

export const changeConsentCollectionPreferencePageTranslationOverview = payload => {
	consentCollectionPreferencePageTranslationOverviewState.value = payload
}

export const consentCollectionPreferencePageSearchQuery = computed({
	get () {
		return consentCollectionPreferencePageSearchQueryState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionPreferencePageSearchQuery(value)
		// your setter
	}
})

export const consentCollectionPreferencePageTranslationStatusId = computed({
	get () {
		return consentCollectionPreferencePageTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionPreferencePageTranslationStatusId(value)
		// your setter
	}
})

export const consentCollectionPreferencePageAuthType = computed({
	get () {
		return consentCollectionPreferencePageAuthTypeState.value
	},
	set (value) {
		changeConsentCollectionPreferencePageAuthType(value)
	}
})

export const consentCollectionPreferencePageLanguageSelected = computed({
	get () {
		return consentCollectionPreferencePageLanguageSelectedState.value
	},
	set (value) {
		changeConsentCollectionPreferencePageTranslationLanguageSelected(value)
	}
})

export const consentCollectionPreferencePageTranslationOverview = computed(
	() => consentCollectionPreferencePageTranslationOverviewState.value
)

export const resetConsentCollectionPreferencePageFilters = () => {
	changeConsentCollectionPreferencePageSearchQuery(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER)
	changeConsentCollectionPreferencePageTranslationStatusId(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED)
	changeConsentCollectionPreferencePageAuthType(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE)
	changeConsentCollectionPreferencePageTranslationLanguageSelected(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED)
}

export const resetConsentCollectionPreferencePageTranslationOverview = () => {
	changeConsentCollectionPreferencePageTranslationOverview(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW)
}
