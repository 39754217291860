import { resetAccessKeyFilters } from '../state/access-keys.js'
import { resetAdminPortalUserFilters } from '../state/admin-portal-users-and-lookup-data.js'
import { resetAdminPortalBrandFilter } from '../state/brands.js'
import { resetChannelFilters } from '../state/channels.js'
import { resetConfirmationPageFilters } from '../state/confirmation-pages.js'
import { resetConnectorServiceFilters } from '../state/connector-service.js'
import { resetConsentCollectionWidgetFilters } from '../state/consent-collection-widgets.js'
import { resetConsentCollectionPreferencePageFilters } from '../state/consent-collection-preference-pages.js'
import { resetCookieAppearanceTemplateFilters } from '../state/cookie-appearance-templates.js'
import { resetCookieBannerFilters } from '../state/cookie-banners.js'
import { resetCookieCategoryFilters } from '../state/cookie-categories.js'
import { resetCookieCollectionTemplateFilters } from '../state/cookie-collection-templates.js'
import { resetCookiePolicyTablesFilters } from '../state/cookie-policy-tables.js'
import { resetCookieScriptFilters } from '../state/cookie-scripts.js'
import { resetCookieTextTemplateFilters } from '../state/cookie-text-templates.js'
import { resetExtendedPreferencesFilters } from '../state/extended-preferences.js'
import { resetFooterFilters } from '../state/footers.js'
import { resetHeaderFilters } from '../state/headers.js'
import { resetLinkFilters } from '../state/links.js'
import { resetMetaDataFilters } from '../state/meta-data.js'
import { resetPreferenceWidgetFilters } from '../state/preference-widgets.js'
import { resetPrivacyPolicyFilters } from '../state/privacy-policies.js'
import { resetProductRoleFilters } from '../state/product-roles.js'
import { resetProgressiveProfilingFilters } from '../state/progressive-profiling.js'
import { resetSiteScannerFilters } from '../state/site-scanner.js'
import { resetStatementFilters } from '../state/statements.js'
import { resetSystemEmailFilters } from '../state/system-emails.js'
import { resetSystemUserFilters } from '../state/system-users.js'

export const resetAdminPortalFilters = () => {
	// access keys
	resetAccessKeyFilters()

	// admin portal users
	resetAdminPortalUserFilters()

	// brands
	resetAdminPortalBrandFilter()

	// channels
	resetChannelFilters()

	// confirmation pages
	resetConfirmationPageFilters()

	// connector service
	resetConnectorServiceFilters()

	// consent collection widgets
	resetConsentCollectionWidgetFilters()

	// consent collection preference pages
	resetConsentCollectionPreferencePageFilters()

	// cookie appearance templates
	resetCookieAppearanceTemplateFilters()

	// cookie banners
	resetCookieBannerFilters()

	// cookie categories
	resetCookieCategoryFilters()

	// cookie collection templates
	resetCookieCollectionTemplateFilters()

	// cookie policy tables
	resetCookiePolicyTablesFilters()

	// cookie scripts
	resetCookieScriptFilters()

	// cookie text templates
	resetCookieTextTemplateFilters()

	// extended preferences
	resetExtendedPreferencesFilters()

	// footers
	resetFooterFilters()

	// headers
	resetHeaderFilters()

	// links
	resetLinkFilters()

	// meta data
	resetMetaDataFilters()

	// preference widgets
	resetPreferenceWidgetFilters()

	// privacy policies
	resetPrivacyPolicyFilters()

	// product roles
	resetProductRoleFilters()

	// progressive profiling
	resetProgressiveProfilingFilters()

	// site scanner
	resetSiteScannerFilters()

	// statements
	resetStatementFilters()

	// system emails
	resetSystemEmailFilters()

	// system users
	resetSystemUserFilters()
}
